import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DocumentValidator } from 'src/app/validators/document.validator';

@Component({
  selector: 'app-signup-document',
  templateUrl: './signup-document.component.html',
  styleUrls: ['./signup-document.component.scss']
})
export class SignupDocumentComponent implements OnInit {

  public form = this.formBuilder.group({
    document: ['', [Validators.required, DocumentValidator]]
  });

  constructor(private userService: UserService,
              private formBuilder: FormBuilder,
              private router: Router,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  next(): void {
    if (this.form.invalid) {
      return;
    }

    this.userService.search(this.form.get('document')?.value).subscribe(
      user => {
        this.router.navigateByUrl('/signup/confirmation', { state: { user } });
      },
      error => {
        this.snackBar.open(error.error.message, 'OK');
      }
    );
  }
}

<nav *ngIf="!me?.isAdmin()">
    <a [routerLink]="['/']" [class.active]="router.url === '/'"><img src="../assets/images/icon-home.svg" alt="Ícone de uma casa." />&nbsp;início</a>
    <a [routerLink]="['/buy']" [class.active]="router.url === '/buy'"><img src="../assets/images/icon-buy.svg" alt="Ícone de um cartão de crédito." />&nbsp;quero comprar</a>
    <a [routerLink]="['/sell']" [class.active]="router.url === '/sell'"><img src="../assets/images/icon-sell.svg" alt="Ícone de um cartão com sifrão de dinheiro." />&nbsp;quero vender</a>
    <a [routerLink]="['/transactions']" [class.active]="router.url === '/transactions'"><img src="../assets/images/icon-buy.svg" alt="Ícone de um cartão de crédito." />&nbsp;transações</a>
</nav>
<nav *ngIf="me?.isAdmin()">
  <a [routerLink]="['/']" [class.active]="router.url === '/'"><img src="../assets/images/icon-home.svg" alt="Ícone de uma casa." />&nbsp;início</a>
  <a [routerLink]="['/admin/users']" [class.active]="router.url === '/admin/users'"><img src="../assets/images/icon-buy.svg" alt="Ícone de um cartão de crédito." />&nbsp;usuários</a>
  <a [routerLink]="['/admin/precatories']" [class.active]="router.url === '/admin/precatories'"><img src="../assets/images/icon-sell.svg" alt="Ícone de um cartão com sifrão de dinheiro." />&nbsp;precatórios</a>
  <a [routerLink]="['/admin/offers']" [class.active]="router.url === '/admin/offers'"><img src="../assets/images/icon-buy.svg" alt="Ícone de um cartão de crédito." />&nbsp;ofertas</a>
  <a [routerLink]="['/admin/transactions']" [class.active]="router.url === '/admin/transactions'"><img src="../assets/images/icon-buy.svg" alt="Ícone de um cartão de crédito." />&nbsp;transações</a>
</nav>
<nav>
    <a href="javascript:void(0);" href="https://leeq.com.br/" class="secondary_link"><img src="../assets/images/icon-about.svg" alt="Ícone de um edifício." />&nbsp;institucional</a>
    <a href="javascript:void(0);" href="https://www.leeq.com.br/faq/" class="secondary_link"><img src="../assets/images/icon-help.svg" alt="Ícone de um círculo com uma interrogação dentro." />&nbsp;ajuda</a>
    <a href="javascript:void(0);" (click)="logout()" class="secondary_link"><img src="../assets/images/icon-logout.svg" alt="Ícone de uma porta com uma seta indicando saída." />&nbsp;sair</a>
</nav>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ISearchedUser } from '../domain/ISearchedUser';
import { IUserResponse } from '../domain/IUserResponse';
import { User } from '../domain/User';
import { Notification } from '../domain/Notification';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public me$!: Observable<User|null>;

  constructor(private http: HttpClient) {
    this.loadMe();
  }

  loadMe(): void {
    if (!localStorage.getItem('token')) {
      this.me$ = of(null);
    } else {
      this.me$ = this.http.get<IUserResponse>(`${environment.apiUrlBase}/user/me`)
        .pipe(
          map(response => User.fromVO(response)),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(e => throwError(e))
        ) as Observable<User>;
    }
  }

  search(document: string): Observable<User> {
    return this.http.get<ISearchedUser>(`${environment.apiUrlBase}/user/${document}`).pipe(
      map(vo => User.fromSearch(vo)),
      catchError(e => throwError(e))
    );
  }

  create(user: User): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/user`, user);
  }

  resetPass(password: string, token: string): Observable<any> {
    return this.http.post(`${environment.apiUrlBase}/user/reset`, { password, token });
  }

  list(): Observable<User[]> {
    return this.http.get<IUserResponse[]>(`${environment.apiUrlBase}/user`).pipe(
      map(response => response.map(user => User.fromVO(user))),
      catchError(e => throwError(e))
    );
  }

  validEmail(email: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/user/email`, { email });
  }

  updateEmail(userId: number, email: string): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/user/${userId}`, { email });
  }

  getNotifications(): Observable<Notification[]> {
    return this.http.get<any[]>(`${environment.apiUrlBase}/user/notification`)
      .pipe(
        map(response => response.map(notification => Notification.fromVO(notification))),
        catchError(e => throwError(e))
      );
  }

  viewNotification(id: number): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/user/notification/${id}`, null);
  }

  delete(user: User): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrlBase}/user/${user.id}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Precatory } from 'src/app/domain/Precatory';
import { PrecatoryService } from 'src/app/services/precatory.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit {

  public precatories!: Precatory[];

  public type!: number;
  public year!: number;
  public amount!: number;

  constructor(private precatoryService: PrecatoryService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.find();
  }

  find(): void {
    this.precatoryService.findPrecatories(this.type, this.year, this.amount).subscribe(
      precatories => this.precatories = precatories
    );
  }

  yearList(): number[] {
    const list = [];

    let i = 1989;
    while (i <= new Date().getFullYear() + 2) {
      list.push(i);
      i++;
    }

    return list;
  }
}

<section class="buy">
  <div class="title">
      <h2>Quero comprar</h2>
      <p>Aqui você vai encontrar as melhores oportunidades do mercado para aquisição e venda de precatórios.</p>
  </div>

  <section class="list">
    <h3>Precatórios a venda</h3>
    <div class="filter">
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <select matNativeControl [(ngModel)]="type" (change)="find()">
          <option></option>
          <option [value]="1">Federal</option>
          <option [value]="2">Estadual</option>
          <option [value]="3">Municipal</option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ano orçamentário</mat-label>
        <select matNativeControl [(ngModel)]="year" (change)="find()">
          <option value=""></option>
          <option *ngFor="let i of yearList()" value="{{ i }}">{{ i }}</option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Valor com deságio</mat-label>
        <select matNativeControl [(ngModel)]="amount" (change)="find()">
          <option></option>
          <option [value]="1">Até R$ 100.000,00</option>
          <option [value]="2">De 100.000,01 até 250.000,00</option>
          <option [value]="3">De 250.000,01 até 500.000,00</option>
          <option [value]="4">Acima de 500.000,00</option>
        </select>
      </mat-form-field>
    </div>
    <ul class="table">
      <li class="header">
        <span>Número</span>
        <span>Tipo</span>
        <span>Ano <br/>orçamentário</span>
        <span>Valor total</span>
        <span>Com deságio</span>
        <span></span>
        <span></span>
      </li>
      <li *ngFor="let item of precatories">
        <span>{{ item['number'] }}</span>
        <span>{{ item['precatoryTypeLabel'] }}</span>
        <span>{{ item['budgetYear'] }}</span>
        <span>{{ item['amount'] | brCurrency }}</span>
        <span>{{ item['amountWithDiscount'] | brCurrency }}</span>
        <span>
          <a [routerLink]="['/buy/' + item.id]"><mat-icon>search</mat-icon></a>
        </span>
        <span class="actions">
          <a *ngIf="item.isCanceled()" [routerLink]="['/buy/' + item.id]" class="btn btn-ghost status-0">transação cancelada</a>
          <a *ngIf="!item.isCanceled() && !item.hasOffer()" [routerLink]="['/buy/' + item.id]" class="btn btn-primary">fazer oferta</a>
          <a *ngIf="!item.isCanceled() && item.hasOffer()" [routerLink]="['/buy/' + item.id]" class="btn btn-ghost status-{{item.getOfferStatus().value}}">
            {{ item.getOfferStatus().label.toLowerCase() }}
          </a>
        </span>
      </li>
    </ul>
    <div class="empty" *ngIf="!precatories || !precatories.length">Não temos nenhum precatório disponível que atenda sua busca.</div>
  </section>

</section>


<section>
  <div class="title">
      <h2>Precatórios</h2>
      <p>Lista de precatórios cadastrados na Leeq.</p>
  </div>

  <section class="list">
    <h3>Precatórios</h3>
    <div class="filter">
      <mat-form-field>
        <mat-label>Tipo</mat-label>
        <select matNativeControl [(ngModel)]="type" (change)="find()">
          <option></option>
          <option [value]="1">Federal</option>
          <option [value]="2">Estadual</option>
          <option [value]="3">Municipal</option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ano orçamentário</mat-label>
        <select matNativeControl [(ngModel)]="year" (change)="find()">
          <option value=""></option>
          <option *ngFor="let i of yearList()" value="{{ i }}">{{ i }}</option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Valor com deságio</mat-label>
        <select matNativeControl [(ngModel)]="amount" (change)="find()">
          <option></option>
          <option [value]="1">Até R$ 100.000,00</option>
          <option [value]="2">De 100.000,01 até 250.000,00</option>
          <option [value]="3">De 250.000,01 até 500.000,00</option>
          <option [value]="4">Acima de 500.000,00</option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <select matNativeControl [(ngModel)]="status" (change)="find()">
          <option></option>
          <option [value]="0">Reprovado</option>
          <option [value]="1">Em análise</option>
          <option [value]="2">Precatório aprovado</option>
          <option [value]="3">Em transação</option>
          <option [value]="4">Vendido</option>
        </select>
      </mat-form-field>
    </div>
    <ul class="table">
      <li class="header">
        <span>Número</span>
        <span>Tipo</span>
        <span>Ano <br/>orçamentário</span>
        <span>Valor total</span>
        <span>Com deságio</span>
        <span></span>
        <span></span>
      </li>
      <li *ngFor="let item of precatories">
        <span>{{ item['number'] }}</span>
        <span>{{ item['precatoryTypeLabel'] }}</span>
        <span>{{ item['budgetYear'] }}</span>
        <span>{{ item['amount'] | brCurrency }}</span>
        <span>{{ item['amountWithDiscount'] | brCurrency }}</span>
        <span>
          <a [routerLink]="['/admin/precatories/' + item.id]"><mat-icon>search</mat-icon></a>
        </span>
        <span class="actions" [ngSwitch]="item.status">
          <a [routerLink]="['/admin/precatories/' + item.id]" class="btn btn-primary" *ngSwitchCase="1">avaliar</a>
          <a [routerLink]="['/admin/precatories/' + item.id]" class="btn btn-ghost status-0" *ngSwitchCase="0">reprovado</a>
          <a [routerLink]="['/admin/precatories/' + item.id]" class="btn btn-ghost status-0" *ngSwitchCase="5">cancelado</a>
          <a [routerLink]="['/admin/precatories/' + item.id]" class="btn btn-ghost status-2" *ngSwitchDefault>
            <ng-container *ngIf="item.status === 2">precatório aprovado</ng-container>
            <ng-container *ngIf="item.status === 3">em transação</ng-container>
            <ng-container *ngIf="item.status === 4">vendido</ng-container>
          </a>
        </span>
      </li>
    </ul>
    <div class="empty" *ngIf="!precatories || !precatories.length">Não temos nenhum precatório disponível que atenda sua busca.</div>
  </section>

</section>


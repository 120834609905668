import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Transaction } from 'src/app/domain/Transaction';
import { TransactionService } from 'src/app/services/transaction.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  public qrcode!: SafeResourceUrl;

  constructor(public dialogRef: MatDialogRef<PaymentComponent>,
              private transactionService: TransactionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: {
                transaction: Transaction,
                callback: () => {}
              }) { }

  ngOnInit(): void {
    this.transactionService.getPaymentCode(this.data.transaction).subscribe(response => {
      this.qrcode = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${response.qrcode}`);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  pay(): void {
    this.transactionService.pay(this.data.transaction).subscribe(
      _ => {
        this.close();
        this.dialog.open(ConfirmationModalComponent, {
          data: {
            title: 'Pagamento efetuado com sucesso',
            text: 'Você informou que realizou o pagamento, iremos analisar o depósito e atualizaremos o status da sua transação.',
            close: this.data.callback
          }
        });
      },
      response => {
        this.snackBar.open(response.error.message || 'Erro ao realizar pagamento', 'OK');
      }
    );
  }
}

<!-- <h1>Termos de Uso</h1> -->

<h1>Olá, seja bem vindo! Vamos começar?</h1>
<h1>A Leeq precisa que você leia os termos de uso com atenção. Vai levar apenas alguns minutinhos.</h1>

<p>
  Obrigado por visitar o site da Leeq (EMPRESA). Estes termos e condições estão sujeitos a alterações pela Leeq a qualquer momento e, a nosso critério, ocasião na qual o seu aceite será requerido.
</p>
<p>
  Estes termos e condições regem o uso deste website. Ao acessar este site, você está reconhecendo e aceitando os termos e condições abaixo.
</p>
<p>
  O uso deste site após quaisquer mudanças implementadas, constitui a aceitação das mudanças. Com isso, nós indicamos que você consulte os termos e condições cada vez que usar este website.
</p>

<h3>PROPRIEDADE INTELECTUAL</h3>
<p>
  Você reconhece e concorda que todos os conteúdos e materiais disponíveis neste site são protegidos por direitos autorais, marcas registradas, marcas de serviço, patentes, segredos comerciais e outros direitos de propriedade previstos em leis.
</p>
<p>
  Exceto quando expressamente autorizado pela empresa, você concorda em não vender, licenciar, alugar, modificar, distribuir, copiar, reproduzir, transmitir, exibir publicamente, executar publicamente, publicar, adaptar, modificar ou criar trabalhos derivados de todo e qualquer material e/ou conteúdo deste site.
</p>
<p>
  A reprodução, cópia ou redistribuição para fins comerciais de quaisquer materiais ou elementos de design deste site é estritamente proibida, permissão expressa por escrito da EMPRESA titular do site.
</p>

<h1>Estamos quase lá.</h1>

<h3>LIMITAÇÕES DE UTILIZAÇÃO</h3>
<p>
  Você deve ter pelo menos dezoito (18) anos de idade para acessar este website. Caso não tenha a idade estabelecida, o acesso ao site estará vedado.
</p>
<p>
  A senha cadastrada pelo uso do site é para o seu uso pessoal e intransferível, salvo disposição específica em contrário contida em termo vinculado a este site. Ao cadastrar-se no site, você concorda em ser responsável pela segurança de sua senha.
</p>
<p>A plataforma não será responsável por vulneração da senha pessoal do usuário.</p>

<h3>REFERÊNCIAS DE TERCEIROS / HYPERLINKS</h3>
<p>
  Este site pode estar linkado a outros sites na Internet. Esses sites podem conter informações ou material que algumas pessoas podem entender como impróprio ou ofensivo. Esses outros sites não estão sob o controle da Leeq, de sorte que a EMPRESA não se responsabiliza pela exatidão, cumprimento de direitos autorais, legalidade, decência, ou qualquer outro aspecto do conteúdo de tais sites, não implicando o endosso de outro site, ou qualquer associação com seus operadores.
</p>

<h1>Você chegou até aqui, estamos na metade. Preste atenção nas regras de envio de informações.</h1>

<h3>DO TRATAMENTO DE DADOS ENVIADOS POR VOCÊ</h3>
<p>
  Você garante que todos os dados enviadas à Leeq são verdadeiros e não violam direitos de terceiros, sendo você titular dos poderes legais para apresenta-los. Você concede autorização à EMPRESA para tratar os dados conforme as finalidades correlatas às funções deste site.
</p>
<p>
  A Leeq reserva-se o direito de remover ou excluir, sem aviso prévio, qualquer dado que violar regras ou que não seja apropriado, a exclusivo critério da EMPRESA.
</p>
<p>
  A Leeq reserva-se o direito de cooperar com as autoridades policiais e oficiais de justiça na investigação e repressão de qualquer crime ou ação judicial.
</p>
<p>
  Você poderá revogar o consentimento dado no que atine ao tratamento dos dados inseridos no sistema quando o tratamento lhe parecer incompatível com as funções desse site, mediante solicitação realizada.
</p>
<p>
  Você poderá, a qualquer momento, confirmar a existência e o tratamento conferido aos seus dados, sendo a resposta fornecida no prazo de até 15 (quinze) dias, contado da data do requerimento do titular, indicando a origem dos dados, a inexistência de registro, os critérios utilizados e a finalidade do tratamento, observados os segredos comercial e industrial.
</p>
<p>
  O não fornecimento dos dados solicitados na plataforma implicará na impossibilidade de utilizá-la.
</p>
<p>
  Você poderá acessar todos os dados pessoais inseridos na plataforma.
</p>
<p>
  Você poderá a qualquer momento retificar e/ou corrigir os dados inseridos na plataforma.
</p>
<p>
  A Leeq adotará todas as medidas ao seu alcance para, quando solicitado, possibilitar a portabilidade dos seus dados.
</p>
<p>
  Você poderá solicitar a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com as finalidades do site.
</p>
<p>
  A Leeq só fará o compartilhamento dos seus dados mediante autorização expressa sua.
</p>
<p>
  Você poderá, a qualquer momento, solicitar a exclusão de seus dados da plataforma, contudo, a exclusão não será feita quando tais dados forem necessários para exercício regular de direito pela EMPRESA ou pelo seus usuários.
</p>
<p>
  Você poderá solicitar a revisão das decisões tomadas pelos gestores da plataforma com base no tratamento automatizado dos seus dados.
</p>

<h3>LIMITAÇÃO DE RESPONSABILIDADE</h3>
<p>
  Em hipótese alguma a Leeq, seus funcionários, parceiros e prestadores de serviço, serão responsáveis por qualquer dano direito, indireto, punitivo, acidental ou danos resultantes do uso das informações do site por usuários que, legalmente, tiveram acesso a tais dados.
</p>
<p>
  A responsabilidade da EMPRESA será limitada ao previsto em lei.
</p>
<p>
  A EMPRESA não é responsável pelas informações inseridas pelos usuários no site. Assim, você deverá adotar os cuidados necessários para ter segurança nas transações realizadas através da plataforma.
</p>

<h3>INDENIZAÇÃO</h3>
<p>
  A Leeq não se responsabiliza por eventual má utilização das informações inseridas no site pelos usuários. Nenhuma informação deverá ser tratada como verdade absoluta, por isso não deve ser vista como indenizável por qualquer perda ou dano que surja em decorrência da credibilidade conferida por você a tal informação.
</p>

<h1>Agora sim falta pouquíssimo.</h1>

<h3>SEPARAÇÃO E INTEGRAÇÃO</h3>
<p>
  Estes termos constituem o acordo integral entre você e a Leeq em relação a esta plataforma e substitui todas as comunicações anteriores ou atuais entre você e a EMPRESA. Se qualquer disposição destes Termos e Condições for considerada inválida, essa parte será interpretada conforme a lei aplicável e nas intenções originais das partes. As demais disposições restantes permanecerão em pleno vigor para todos os fins legais.
</p>

<h3>JURISDIÇÃO</h3>
<p>
  Estes Termos de Uso serão regidos e interpretados de acordo com as leis brasileiras. Para resolver os litígios decorrentes destes Termos e Condições fica eleito o Foro Central da Comarca de São Paulo.
</p>

<h1>PARABÉNS! Você concluiu a leitura integral do termo de uso.</h1>
<h1>Agora precisamos que você leia a política de privacidade.</h1>

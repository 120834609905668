import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss']
})
export class AccountConfirmationComponent implements OnInit {

  constructor(private authService: AuthService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams.h;
    this.authService.loginByToken(token).subscribe();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Transaction } from 'src/app/domain/Transaction';
import { TransactionService } from 'src/app/services/transaction.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-contract-drafting',
  templateUrl: './contract-drafting.component.html',
  styleUrls: ['./contract-drafting.component.scss']
})
export class ContractDraftingComponent implements OnInit {

  public loading = false;

  public editorConfig: AngularEditorConfig = {
    editable: true,
    height: '280px',
    sanitize: false
  };

  public form = this.formBuilder.group({
    contract: []
  });

  constructor(public dialogRef: MatDialogRef<ContractDraftingComponent>,
              private transactionService: TransactionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: {
                transaction: Transaction,
                callback: () => {}
              }) { }

  ngOnInit(): void {
    this.loadExample();
  }

  loadExample(): void {
    this.transactionService.getContractBase(this.data.transaction).subscribe(
      contract => this.form.get('contract')?.setValue(contract)
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.loading = true;
    this.transactionService.createContract(this.data.transaction, this.form.get('contract')?.value).subscribe(
      _ => {
        this.loading = false;
        this.close();

        this.dialog.open(ConfirmationModalComponent, {
          data: {
            title: 'Contrato salvo com sucesso',
            text: 'O contrato foi salvo com sucesso e enviado para as partes assinarem',
            close: this.data.callback
          }
        });
      },
      response => {
        this.loading = false;
        this.snackBar.open(response.error.message || 'Erro ao salvar contrato', 'OK');
      }
    );
  }

}

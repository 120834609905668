<mat-icon (click)="close()" class="close">close</mat-icon>
<h2>Pagamento</h2>
<p>Faça o pagamento através do PIX utilizando o QR Code abaixo.</p>
<img *ngIf="qrcode" [src]="qrcode" width="100%" alt="">
<div *ngIf="!qrcode" class="loading-container">
  <div class="aspect-ratio">
    <mat-spinner></mat-spinner>
  </div>
</div>
<p>Pagamento de Precatório Plataforma Leeq</p>
<p class="amount">{{ data.transaction.offer.buyerAmount | brCurrency }}</p>
<form (ngSubmit)="pay()">
  <button class="btn btn-primary">pronto, pagamento efetuado</button>
</form>

<section class="offer">
  <div class="title">
    <h2>Detalhe do precatório</h2>
    <p>Confira abaixo todas as informações do precatório ;)</p>
  </div>

  <section class="list" *ngIf="precatory">
    <app-precatory-details [precatory]="precatory"></app-precatory-details>

    <mat-radio-group *ngIf="precatory.status <= 2" [(ngModel)]="precatory.status" (change)="change()">
      <mat-radio-button [value]="1">Em análise</mat-radio-button>
      <mat-radio-button [value]="2">Aprovado</mat-radio-button>
      <mat-radio-button [value]="0">Reprovado</mat-radio-button>
    </mat-radio-group>
    <div [ngSwitch]="precatory.status">
      <i *ngSwitchCase="3">Precatório já está em fase de proposta</i>
      <i *ngSwitchCase="4">Precatório vendido</i>
      <i *ngSwitchCase="5">Precatório em transação cancelada</i>
    </div>

  </section>

  <div class="back">
    <a href="javascript:history.back()" class="btn btn-primary">voltar</a>
  </div>

</section>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IPrecatoryResponse } from '../domain/IPrecatoryResponse';
import { Offer, OfferStatus } from '../domain/Offer';
import { Precatory, PrecatoryStatus } from '../domain/Precatory';
import { FormDataUtils } from '../utils/form-data';

@Injectable({
  providedIn: 'root'
})
export class PrecatoryService {

  constructor(private http: HttpClient) { }

  create(precatory: Precatory, craft: File, contract?: File, procuration?: File): Observable<void> {

    const formData = new FormData();

    FormDataUtils.buildFormData(formData, precatory);

    formData.append('craft', craft);
    if (contract) { formData.append('contract', contract); }
    if (procuration) { formData.append('procuration', procuration); }

    return this.http.post<void>(`${environment.apiUrlBase}/precatory`, formData);
  }

  update(id: number, precatory: Precatory, craft?: File, contract?: File, procuration?: File): Observable<void> {
    const formData = new FormData();

    FormDataUtils.buildFormData(formData, precatory);

    if (craft) { formData.append('craft', craft); }
    if (contract) { formData.append('contract', contract); }
    if (procuration) { formData.append('procuration', procuration); }

    return this.http.put<void>(`${environment.apiUrlBase}/precatory/${id}`, formData);
  }

  changeStatus(precatory: Precatory, status: PrecatoryStatus): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/precatory/${precatory.id}`, { status });
  }

  getById(id: number): Observable<Precatory> {
    return this.http.get<IPrecatoryResponse>(`${environment.apiUrlBase}/precatory/${id}`)
      .pipe(
        map(response => Precatory.fromVO(response)),
        catchError(e => throwError(e))
      );
  }

  findPrecatories(type?: number, year?: number, amount?: number, status?: number): Observable<Precatory[]> {
    let query = '1=1';

    if (type) { query += `&type=${type}`; }
    if (year) { query += `&year=${year}`; }
    if (amount) { query += `&amount=${amount}`; }
    if (status) { query += `&status=${status}`; }

    return this.http.get<IPrecatoryResponse[]>(`${environment.apiUrlBase}/precatory?${query}`)
      .pipe(
        map(response => response.map(i => Precatory.fromVO(i))),
        catchError(e => throwError(e))
      );
  }

  findUserPrecatories(): Observable<Precatory[]> {
    return this.http.get<IPrecatoryResponse[]>(`${environment.apiUrlBase}/user/me/precatory`)
      .pipe(
        map(response => response.map(i => Precatory.fromVO(i))),
        catchError(e => throwError(e))
      );
  }

  makeOffer(precatory: Precatory, amount: number): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/precatory/${precatory.id}/offer`, { amount });
  }

  updateOffer(precatory: Precatory, offer: Offer, status: OfferStatus): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/precatory/${precatory.id}/offer/${offer.id}`, { status });
  }

  getActiveOffer(precatory: Precatory): Observable<Offer> {
    return this.http.get(`${environment.apiUrlBase}/precatory/${precatory.id}/offer/active`)
      .pipe(
        map(response => Offer.fromVO(response)),
        catchError(e => throwError(e))
      );
  }

  createContract(precatory: Precatory, contract: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/precatory/${precatory.id}/contract`, { contract });
  }
}

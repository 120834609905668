<section class="initial top">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Confirme seus dados</h2>
      <p>Se os seus dados estiverem corretos é só continuar :)</p>

      <form (ngSubmit)="next()">
        <mat-form-field>
          <mat-label>CPF ou CNPJ</mat-label>
          <input matInput [disabled]="true" [(ngModel)]="user.document" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ user.type === 1 ? 'Nome' : 'Razão social' }}</mat-label>
          <input matInput [disabled]="true" [(ngModel)]="user.name" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Data de {{ user.type === 1 ? 'nascimento' : 'abertura' }}</mat-label>
          <input matInput [disabled]="true" [(ngModel)]="user.date" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <div class="buttons">
          <button class="btn btn-primary">continuar</button>
          <a [routerLink]="['/signup/document']" class="btn btn-secondary">voltar</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements AfterViewInit {

  counter = 0;

  constructor(private loading: LoadingService, private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.loading.getVisible()
      .subscribe(increase => {
        this.counter = Math.max(0, this.counter + (increase ? 1 : -1));
        this.cdRef.detectChanges();
      });
  }

}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private subject = new Subject<void>();

  constructor() { }

  toggle(): void {
    const html = document.getElementsByTagName('html')[0];
    html.classList.toggle('no-scroll');

    this.subject.next();
  }

  isOpened(): Observable<void> {
    return this.subject.asObservable();
  }
}

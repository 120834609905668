import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CreditorTypeValidator(value: any, fieldName: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {

    if (control.value === value) {
      const parent = control.parent as FormGroup;

      if (!parent.get(fieldName)?.value) {
        return { creditortypeinvalid: true };
      }
    }

    return null;
  };

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private menuService: MenuService,
              private authService: AuthService) { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.menuService.toggle();
  }

  showMenu(): boolean {
    return this.authService.isLoggedIn();
  }
}

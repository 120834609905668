<h3>Precatório</h3>
<ul *ngIf="precatory">
  <li>
    <p class="title">Tipo do precatório</p>
    <p>{{ precatory.precatoryTypeLabel }}</p>
  </li>
  <li>
    <p class="title">Tipo do processo</p>
    <p>{{ precatory.processTypeLabel }}</p>
  </li>
  <li>
    <p class="title">Precatório nº</p>
    <p>{{ precatory.number }}</p>
  </li>
  <li>
    <p class="title">Processo de origem nº</p>
    <p>{{ precatory.processNumber }}</p>
  </li>
  <li>
    <p class="title">Ano orçamentário</p>
    <p>{{ precatory.budgetYear }}</p>
  </li>
  <li>
    <p class="title">Natureza do crédito</p>
    <p>{{ precatory.creditNatureLabel }}</p>
  </li>
  <li>
    <p class="title">Credor</p>
    <p>{{ precatory.creditorTypeLabel }}</p>
  </li>
  <li>
    <p class="title">Ofício requisitório</p>
    <p><a [href]="precatory.craft" target="_blank">Ver ofício</a></p>
  </li>
  <li>
    <p class="title">Valor total</p>
    <p>{{ precatory.amount | brCurrency }}</p>
  </li>
  <li>
    <p class="title">Valor com deságio</p>
    <p>{{ precatory.amountWithDiscount | brCurrency }}</p>
  </li>
  <li *ngIf="showContract()">
    <p class="title">Contrato</p>
    <p>
      <a (click)="openContract()" target="_blank">link para o contrato</a>
    </p>
  </li>
</ul>

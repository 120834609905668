import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Transaction } from 'src/app/domain/Transaction';
import { TransactionService } from 'src/app/services/transaction.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-finalize-transaction',
  templateUrl: './finalize-transaction.component.html',
  styleUrls: ['./finalize-transaction.component.scss']
})
export class FinalizeTransactionComponent implements OnInit, AfterViewInit {

  @ViewChild('docuSignIframe') docuSignIframe!: ElementRef;

  public loading = true;

  private listener: (event: any) => void = (event) => {
    if (event.data === 'ended') {
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          title: 'Transação finalizada com sucesso.',
          text: 'Mais uma transação foi finalizada pela plataforma da Leeq.',
          close: () => {
            if (this.data.callback) { this.data.callback(); }
          }
        }
      });
      this.close();
    }
  }

  constructor(public dialogRef: MatDialogRef<FinalizeTransactionComponent>,
              private dialog: MatDialog,
              private transactionService: TransactionService,
              @Inject(MAT_DIALOG_DATA) public data: {
                transaction: Transaction,
                callback: () => {}
              }) { }

  ngOnInit(): void {
    this.listener = this.listener.bind(this);
    window.addEventListener('message', this.listener);
  }

  ngAfterViewInit(): void {
    this.transactionService.showContract(this.data.transaction).subscribe(response => {
      this.loading = false;
      this.docuSignIframe.nativeElement.src = response.url;
    });
  }

  close(): void {
    window.removeEventListener('message', this.listener);
    this.dialogRef.close();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Transaction } from '../domain/Transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  getById(id: number): Observable<Transaction> {
    return this.http.get<any>(`${environment.apiUrlBase}/transaction/${id}`)
      .pipe(
        map(response => Transaction.fromVO(response)),
        catchError(e => throwError(e))
      );
  }

  list(): Observable<Transaction[]> {
    return this.http.get<any[]>(`${environment.apiUrlBase}/transaction`)
      .pipe(
        map(response => response.map(t => Transaction.fromVO(t))),
        catchError(e => throwError(e))
      );
  }

  createContract(transaction: Transaction, contract: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/transaction/${transaction.id}/contract`, { contract });
  }

  showContract(transaction: Transaction): Observable<{url: string}> {
    return this.http.get<{url: string}>(`${environment.apiUrlBase}/transaction/${transaction.id}/contract`);
  }

  pay(transaction: Transaction): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/transaction/${transaction.id}/payment`, {});
  }

  finalize(transaction: Transaction): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/transaction/${transaction.id}/payment`, {});
  }

  getContractBase(transaction: Transaction): Observable<string> {
    return this.http.get(`${environment.apiUrlBase}/transaction/${transaction.id}/contract/base`, { responseType: 'text' });
  }

  getPaymentCode(transaction: Transaction): Observable<{qrcode: string}> {
    return this.http.get<{qrcode: string}>(`${environment.apiUrlBase}/transaction/${transaction.id}/payment`);
  }

  rejectPayment(transaction: Transaction): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/transaction/${transaction.id}/payment`, {});
  }

  cancel(transaction: Transaction): Observable<void> {
    return this.http.patch<void>(`${environment.apiUrlBase}/transaction/${transaction.id}`, {});
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Offer } from '../domain/Offer';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpClient) { }

  list(onlyTransactions: boolean = false): Observable<Offer[]> {
    return this.http.get<any[]>(`${environment.apiUrlBase}/offer?transactions=${onlyTransactions}`)
      .pipe(
        map(response => response.map(o => Offer.fromVO(o))),
        catchError(e => throwError(e))
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public nodes: { label: string, link: string }[] = [];
  public currentNode = '';

  public routerMap: any = {
    '': 'Início',
    sell: 'Quero vender',
    new: 'Cadastrar precatório',
    buy: 'Quero comprar',
    edit: 'Editar precatório',
    admin: 'Administração',
    users: 'Usuários',
    precatories: 'Precatórios',
    offers: 'Ofertas',
    transactions: 'Transações',
  };

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.update(this.router.routerState.snapshot.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.update(event.url);
      }
    });
  }

  update(url: string): void {
    let path: string[] = [];
    let current = '';

    if (url !== '/')  {
      path = url.split('/');
      current = path.pop() || '';
    }

    this.nodes = path.map((p, i) => {
      return {
        label: this.routerMap[p] || p,
        link: path.slice(0, i + 1).join('/')
      };
    });

    this.currentNode = this.routerMap[current] || `Detalhe`;
  }

}

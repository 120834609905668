import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Precatory } from 'src/app/domain/Precatory';
import { PrecatoryService } from 'src/app/services/precatory.service';

@Component({
  selector: 'app-admin-precatory-details',
  templateUrl: './admin-precatory-details.component.html',
  styleUrls: ['./admin-precatory-details.component.scss']
})
export class AdminPrecatoryDetailsComponent implements OnInit {

  public precatory!: Precatory;

  constructor(private precatoryService: PrecatoryService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.precatoryService.getById(this.route.snapshot.params.id).subscribe(
      precatory => {
        this.precatory = precatory;
      }
    );
  }

  change(): void {
    this.precatoryService.changeStatus(this.precatory, this.precatory.status).subscribe(
      _ => this.snackBar.open('Precatório atualizado com sucesso', 'OK'),
      response => this.snackBar.open(response.error.message || 'Erro ao atualizar precatório', 'OK')
    );
  }

}

import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-seller-contract',
  templateUrl: './seller-contract.component.html',
  styleUrls: ['./seller-contract.component.scss']
})
export class SellerContractComponent implements OnInit {

  @Input() partialConfirm?: () => void;

  accepted = false;

  constructor() { }

  ngOnInit(): void {
  }

  getDate(): string {
    return moment().locale('pt-br').format('D [de] MMMM [de] YYYY');
  }

  accept(): void {
    this.accepted = true;
    if (this.partialConfirm) {
      this.partialConfirm();
    }
  }

}

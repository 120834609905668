import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { ContractDraftingComponent } from 'src/app/components/contract-drafting/contract-drafting.component';
import { FinalizeTransactionComponent } from 'src/app/components/finalize-transaction/finalize-transaction.component';
import { PaymentComponent } from 'src/app/components/payment/payment.component';
import { SignatureComponent } from 'src/app/components/signature/signature.component';
import { Transaction } from 'src/app/domain/Transaction';
import { User } from 'src/app/domain/User';
import { TransactionService } from 'src/app/services/transaction.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {

  public transaction!: Transaction;
  public me!: User;

  constructor(private transactionService: TransactionService,
              private userService: UserService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.userService.me$.subscribe(me => this.me = me as User);
    this.load();
  }

  load(): void {
    this.transactionService.getById(this.route.snapshot.params.id).subscribe(
      transaction => this.transaction = transaction
    );
  }

  editContract(): void {
    this.dialog.open(ContractDraftingComponent, { data: { transaction: this.transaction, callback: this.load.bind(this) } });
  }

  sign(): void {
    this.dialog.open(SignatureComponent, { data: { transaction: this.transaction, callback: this.load.bind(this) } });
  }

  pay(): void {
    this.dialog.open(PaymentComponent, { data: { transaction: this.transaction,  callback: this.load.bind(this) } });
  }

  private finalize(): void {
    this.dialog.open(FinalizeTransactionComponent, { data: { transaction: this.transaction, callback: this.load.bind(this) } });
  }

  download(): void {
    this.transactionService.showContract(this.transaction).subscribe(response => {
      window.open(response.url);
    });
  }

  cancel(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Cancelar transação',
        text: 'Tem certeza que deseja cancelar essa transação? <br><br> <b>Essa ação é permanente e não poderá ser desfeita.</b>',
        buttonLabel: 'sim, tenho certeza',
        callback: () => {
          this.transactionService.cancel(this.transaction).subscribe(
            response => {
              this.snackBar.open('Transação cancelada com sucesso', 'OK');
              this.load();
            },
            response => {
              this.snackBar.open('Erro ao cancelar transação', 'OK');
            }
          );
        }
      }
    });
  }

  rejectPayment(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Reprovar pagamento',
        text: 'Tem certeza que deseja reprovar o pagamento?',
        buttonLabel: 'sim, tenho certeza',
        callback: () => {
          this.transactionService.rejectPayment(this.transaction).subscribe(
            response => {
              this.snackBar.open('O pagamento foi reprovado', 'OK');
              this.load();
            },
            response => {
              this.snackBar.open('Erro ao rejeitar reprovado', 'OK');
            }
          );
        }
      }
    });
  }

  approvePayment(): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Aprovar pagamento',
        text: 'Tem certeza que deseja aprovar o pagamento?',
        buttonLabel: 'sim, tenho certeza',
        callback: () => {
          this.finalize();
        }
      }
    });
  }

  isBuyer(): boolean {
    return this.me?.id === this.transaction.buyer?.id;
  }

  alreadySign(): boolean {
    return this.isBuyer() && this.transaction.buyerSignature || !this.isBuyer() && this.transaction.sellerSignature;
  }

  showBuyerAmount(): boolean {
    return this.me?.isAdmin() || this.me?.id === this.transaction.buyer?.id;
  }

  showSellerAmount(): boolean {
    return this.me?.isAdmin() || this.me?.id === this.transaction.seller?.id;
  }
}

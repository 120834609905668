<section class="initial">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Faça seu login</h2>
      <p>Já tem cadastro? Digite seu e-mail e senha.</p>

      <form [formGroup]="form" (ngSubmit)="login()">
        <mat-form-field>
          <mat-label>Digite seu e-mail</mat-label>
          <input matInput placeholder="teste@leeq.com" formControlName="email">
          <mat-error *ngIf="form.get('email')?.hasError('email') && !form.get('email')?.hasError('required')">
            Formato de e-mail <strong>inválido</strong>
          </mat-error>
          <mat-error *ngIf="form.get('email')?.hasError('required')">
            E-mail é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Digite sua senha</mat-label>
          <input type="password" matInput formControlName="password">
          <a [routerLink]="['/forgot']" matSuffix>Esqueceu?</a>
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            Senha é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <div class="buttons">
          <button class="btn btn-primary">entrar</button>
          <a [routerLink]="['/signup']" class="btn btn-secondary">sou novo aqui</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Precatory } from 'src/app/domain/Precatory';
import { PrecatoryService } from 'src/app/services/precatory.service';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.scss']
})
export class SellComponent implements OnInit {

  public precatories!: Precatory[];

  constructor(private precatoryService: PrecatoryService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.precatoryService.findUserPrecatories().subscribe(
      precatories => this.precatories = precatories
    );
  }

  edit(): void {
    this.snackBar.open('Edição ainda está em construção', 'OK');
  }

  view(): void {

  }

}

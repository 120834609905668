import { ISearchedUser } from './ISearchedUser';
import { IUserResponse } from './IUserResponse';

export class User {
  id!: number;
  name!: string;
  document!: string;
  date!: string;
  type!: UserType;
  verified!: boolean;

  email?: string;
  phone?: string;
  password?: string;

  address?: {
    id: number;
    zip: string;
    street: string;
    district: string;
    city: string;
    province: string;
  };

  static fromSearch(vo: ISearchedUser): User {
    const u = new User();

    u.name = vo.name;
    u.document = vo.document;
    u.date = vo.date;
    u.type = vo.type;

    return u;
  }

  static fromVO(vo: IUserResponse): User {
    const u = new User();

    u.id = vo.id;
    u.name = vo.name;
    u.document = vo.document;
    u.date = vo.date;
    u.type = vo.type;
    u.verified = vo.verified;

    u.email = vo.email;
    u.phone = vo.phone;

    u.address = vo.address;

    return u;
  }

  firstName(): string {
    return this.name.split(' ')[0];
  }

  isAdmin(): boolean {
    return this.type === UserType.ADMIN;
  }

  isCPF(): boolean {
    return this.type === UserType.CPF;
  }

  isCNPJ(): boolean {
    return this.type === UserType.CNPJ;
  }
}

export enum UserType {
  CPF = 1, CNPJ, ADMIN
}

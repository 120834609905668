<section class="initial">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Ótimo! Agora é só verificar o seu e-mail</h2>
      <p>Clique no link que enviamos por e-mail para completar o seu cadastro. Lembre-se de verificar se o e-mail não foi para a pasta de spam.</p>
      <form>
        <div class="buttons">
          <a [routerLink]="['/login']" class="btn btn-primary">login</a>
          <a [routerLink]="['/signup']" class="btn btn-secondary">criar nova conta</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-buyer-contract',
  templateUrl: './buyer-contract.component.html',
  styleUrls: ['./buyer-contract.component.scss']
})
export class BuyerContractComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getDate(): string {
    return moment().locale('pt-br').format('D [de] MMMM [de] YYYY');
  }

}

<section>
  <div class="title">
      <h2>Ofertas</h2>
      <p>Lista de ofertas enviadas.</p>
  </div>

  <section class="list">
    <h3>Ofertas</h3>
    <ul class="table">
      <li class="header">
        <span>Precatório</span>
        <span>Valor</span>
        <span>Valor com deságio</span>
        <span>Valor da oferta</span>
        <span>Data da oferta</span>
        <span>Status</span>
      </li>
      <li *ngFor="let offer of offers">
        <span>{{ offer.precatory.number }}</span>
        <span>{{ offer.precatory.amount | brCurrency }}</span>
        <span>{{ offer.precatory.amountWithDiscount | brCurrency }}</span>
        <span>{{ offer.amount | brCurrency }}</span>
        <span>{{ offer.date | date:'dd/MM/YYYY' }}</span>
        <span class="status-{{ offer.status }}">{{ offer.statusLabel }}</span>
      </li>
    </ul>
    <div class="empty" *ngIf="!offers || !offers.length">Ainda não temos nenhuma oferta enviada.</div>
  </section>

</section>

<section class="initial">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Esqueceu a senha?</h2>
      <p>Não se preocupe, digite seu e-mail abaixo e receba por e-mail as instruções para criar uma nova senha.</p>

      <form [formGroup]="form" (ngSubmit)="confirm()">
        <mat-form-field>
          <mat-label>Digite seu e-mail</mat-label>
          <input matInput placeholder="teste@leeq.com" formControlName="email">
          <mat-error>
            Formato de e-mail <strong>inválido</strong>
          </mat-error>
          <mat-error>
            E-mail é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <div class="buttons">
          <button class="btn btn-primary">enviar</button>
          <a [routerLink]="['/login']" class="btn btn-secondary">voltar</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

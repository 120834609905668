import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-error',
  templateUrl: './account-error.component.html',
  styleUrls: ['./account-error.component.scss']
})
export class AccountErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {

  public form = this.formBuilder.group({
    email: ['', [Validators.required]]
  });

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.authService.forgotPass(this.form.value).subscribe(response => {
      this.snackBar.open('Enviado com sucesso! Confira as instruções no seu e-mail.', 'OK');
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Precatory } from 'src/app/domain/Precatory';
import { Transaction } from 'src/app/domain/Transaction';
import { User } from 'src/app/domain/User';
import { TransactionService } from 'src/app/services/transaction.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-precatory-details',
  templateUrl: './precatory-details.component.html',
  styleUrls: ['./precatory-details.component.scss']
})
export class PrecatoryDetailsComponent implements OnInit {

  @Input() precatory!: Precatory;
  @Input() transaction!: Transaction;

  private me!: User;

  constructor(private userService: UserService, private transactionService: TransactionService) { }

  ngOnInit(): void {
    this.userService.me$.subscribe(me => this.me = me as User);
  }

  showContract(): boolean {
    const transaction = this.precatory.transaction || this.transaction;
    return transaction?.status > 1 && this.me.isAdmin();
  }

  openContract(): void {
    this.transactionService.showContract(this.transaction).subscribe(response => {
      window.open(response.url);
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contract-modal-container',
  templateUrl: './contract-modal-container.component.html',
  styleUrls: ['./contract-modal-container.component.scss']
})
export class ContractModalContainerComponent implements OnInit {

  partial: boolean = !this.data.seller;

  constructor(public dialogRef: MatDialogRef<ContractModalContainerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                seller: boolean,
                callback?: () => {}
              }) { }

  ngOnInit(): void {
  }

  confirm(): void {
    if (!this.partial) { return; }

    if (this.data.callback) {
      this.data.callback();
    }

    this.dialogRef.close();
  }

  partialConfirm(): void {
    this.partial = true;
  }

}

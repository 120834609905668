<section>
  <div class="title">
      <h2>Usuários</h2>
      <p>Lista de usuários cadastrados na Leeq.</p>
  </div>

  <section class="list">
    <h3>Usuários</h3>
    <ul class="table">
      <li class="header">
        <span>Nome / Razão social</span>
        <span>Documento</span>
        <span>E-mail</span>
        <span class="center">Verificado</span>
        <span class="center actions">Ações</span>
      </li>
      <li *ngFor="let user of users">
        <span>{{ user.name }}</span>
        <span>{{ user.document | document }}</span>
        <span *ngIf="userIdEditing !== user.id" (dblclick)="editEmail(user)">
          {{ user.email }}
        </span>
        <span *ngIf="userIdEditing === user.id">
          <mat-form-field>
            <mat-label>Digite o e-mail</mat-label>
            <input [formControl]="control" (focusout)="save()" matInput placeholder="teste@leeq.com">
            <mat-error>
              Formato de e-mail <strong>inválido</strong>
            </mat-error>
          </mat-form-field>
        </span>
        <span class="center"><mat-icon>{{ user.verified ? 'check_circle' : 'cancel' }}</mat-icon></span>
        <span class="center actions">
          <mat-icon *ngIf="!user.isAdmin()" class="red" (click)="delete(user)">delete</mat-icon>
        </span>
      </li>
    </ul>
  </section>

</section>


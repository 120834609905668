import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                title: string,
                text: string,
                buttonLabel: string,
                callback?: () => {},
                close?: () => {},
              }) { }

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.close) {
        this.data.close();
      }
    });
  }

  callback(): void {
    this.dialogRef.close();
    if (this.data.callback) {
      this.data.callback();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}

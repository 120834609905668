<section class="sell">
    <div class="title">
        <h2>Quero vender</h2>
        <p>Aqui você vai encontrar as melhores oportunidades do mercado para aquisição e venda de precatórios.</p>
    </div>
    <a [routerLink]="['/sell/new']"  class="btn btn-primary">cadastrar novo precatório</a>

    <section class="list">
      <h3>Meus precatórios a venda</h3>
      <ul class="table">
        <li class="header">
          <span>Número</span>
          <span>Tipo</span>
          <span>Ano <br/>orçamentário</span>
          <span>Valor total</span>
          <span>Com deságio</span>
          <span></span>
          <span></span>
        </li>
        <li *ngFor="let item of precatories">
          <span>{{ item['number'] }}</span>
          <span>{{ item['precatoryTypeLabel'] }}</span>
          <span>{{ item['budgetYear'] }}</span>
          <span>{{ item['amount'] | brCurrency }}</span>
          <span>{{ item['amountWithDiscount'] | brCurrency }}</span>
          <span>
            <a [routerLink]="['/sell/' + item['id']]"><mat-icon>search</mat-icon></a>
          </span>
          <span class="actions">
            <a *ngIf="item.isCanceled()" [routerLink]="['/sell/' + item.id]" class="btn btn-ghost status-0">transação cancelada</a>
            <a *ngIf="!item.isCanceled() && !item.hasOffer()" [routerLink]="['/sell/' + item['id']]" class="btn btn-ghost status-{{ item.status }}">{{ item.statusLabel.toLowerCase() }}</a>
            <a *ngIf="!item.isCanceled() && item.hasOffer()" [routerLink]="['/sell/' + item['id']]" class="btn btn-primary">ver ofertas</a>
          </span>
        </li>
      </ul>
      <div class="empty" *ngIf="!precatories || !precatories.length">Você ainda não tem nenhum precatório cadastrado.</div>
    </section>
</section>


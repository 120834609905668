<mat-accordion>
  <mat-expansion-panel [expanded]="!terms" hideToggle [disabled]="terms">
    <mat-expansion-panel-header>
      <h3>Termos de Uso</h3>
    </mat-expansion-panel-header>
    <app-terms-of-use></app-terms-of-use>
    <button (click)="continue()" class="btn btn-primary">estou de acordo</button>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="terms" hideToggle [disabled]="!terms">
    <mat-expansion-panel-header>
      <h3>Política de Privacidade</h3>
    </mat-expansion-panel-header>
    <app-privacy-policy></app-privacy-policy>
    <button (click)="accept()" class="btn btn-primary">estou de acordo</button>
  </mat-expansion-panel>
</mat-accordion>

import { AbstractControl } from '@angular/forms';

export function ZipCodeValidator(control: AbstractControl): {[key: string]: any} | null {
  const regex = /^\d{8}$/;

  if (control.value && !control.value.match(regex)) {
    return { zipinvalid: true };
  }

  return null;
}

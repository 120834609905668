import { Component, OnInit } from '@angular/core';
import { Offer } from 'src/app/domain/Offer';
import { Transaction } from 'src/app/domain/Transaction';
import { User } from 'src/app/domain/User';
import { TransactionService } from 'src/app/services/transaction.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent implements OnInit {

  public me!: User;
  public offers: Offer[] = [];
  public transactions: Transaction[] = [];

  constructor(private transactionService: TransactionService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.userService.me$.subscribe(me => this.me = me as User);
    this.transactionService.list().subscribe(
      transactions => this.transactions = transactions
    );
  }

}

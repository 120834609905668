import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/domain/User';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public me!: User;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.me$.subscribe(me => this.me = me as User);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { User } from 'src/app/domain/User';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {

  public users: User[] = [];
  public userIdEditing = 0;

  public control = new FormControl('', [Validators.email]);

  constructor(private userService: UserService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.load();
  }

  private load(): void {
    this.userService.list().subscribe(
      users => this.users = users
    );
  }

  editEmail(user: User): void {
    this.userIdEditing = user.id;
    this.control.setValue(user.email);
  }

  save(): void {
    if (this.control.valid) {
      this.userService.updateEmail(this.userIdEditing, this.control.value).subscribe(
        response => {
          this.snackBar.open('E-mail alterado com sucesso', 'OK');
          this.load();
          this.userIdEditing = 0;
        },
        error => {
          this.snackBar.open('Erro ao alterar e-mail', 'OK');
          this.userIdEditing = 0;
        }
      );
    } else {
      this.userIdEditing = 0;
    }
  }

  delete(user: User): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Excluir usuário',
        text: `Tem certeza que deseja excluir o usuário ${user.name}?`,
        buttonLabel: 'sim, tenho certeza',
        callback: () => {
          this.userService.delete(user).subscribe(
            response => {
              this.snackBar.open('Usuário excluído com sucesso', 'OK');
              this.load();
            },
            response => {
              this.snackBar.open(response.error.message || 'Erro ao excluir usuário', 'OK');
            }
          );
        }
      }
    });
  }

}

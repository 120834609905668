<section class="offer">
  <div class="title">
    <h2>Detalhe do precatório</h2>
    <p>Confira abaixo todas as informações do precatório ;)</p>
  </div>

  <section class="list" *ngIf="precatory">
    <app-precatory-details [precatory]="precatory"></app-precatory-details>

    <form *ngIf="precatory?.hasOffer()">
      <div *ngFor="let offer of precatory?.offers">
        <mat-form-field>
          <mat-label>Valor da oferta</mat-label>
          <input disabled matInput currencyMask [value]="comissionAmountLabel(offer.amount, offer.buyerComission)">
        </mat-form-field>
        <ng-container *ngIf="!precatory?.isCanceled()">
          <button disabled class="disabled status-{{ offer.status }} btn btn-ghost">{{ offer.statusLabel.toLowerCase() }}</button>
          <a *ngIf="offer.isAccepted()" [routerLink]="['/transactions/' + precatory?.transaction?.id]" class="btn btn-ghost btn-transaction">ir para a transação</a>
        </ng-container>
        <ng-container *ngIf="precatory?.isCanceled()">
          <button disabled class="disabled status-{{ offer.status }} btn btn-ghost">{{ offer.statusLabel.toLowerCase() }}</button>
          <a *ngIf="offer.isAccepted()" [routerLink]="['/transactions/' + precatory?.transaction?.id]" class="btn btn-ghost btn-transaction status-0">transação cancelada</a>
        </ng-container>
      </div>
    </form>

    <form *ngIf="precatory?.canOffer()" [formGroup]="form" (ngSubmit)="offer()" class="values">
      <mat-form-field>
        <mat-label>Valor da oferta</mat-label>
        <input matInput formControlName="amount" currencyMask>
        <mat-error *ngIf="form.get('amount')?.hasError('required')">
          Valor da oferta é <strong>obrigatório</strong>
        </mat-error>
        <mat-error *ngIf="form.get('amount')?.hasError('min')">
          O valor deve ser <strong>maior</strong> que <strong>{{ precatory.amountWithDiscount * 0.6 | brCurrency }}</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Valor total com comissão</mat-label>
        <input disabled matInput currencyMask [value]="comissionAmountLabel(form.get('amount')?.value)">
      </mat-form-field>
      <button class="btn btn-primary">{{ precatory?.hasOffer() ? 'fazer nova oferta' : 'fazer oferta'}}</button>
    </form>
  </section>

  <div class="back">
    <a href="javascript:history.back()" class="btn btn-primary">voltar</a>
  </div>

</section>

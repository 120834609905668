import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { User } from 'src/app/domain/User';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  me!: User;
  public isOpened = false;

  private dialogRef?: MatDialogRef<ConfirmationModalComponent>;

  constructor(private userService: UserService,
              private menuService: MenuService,
              private authService: AuthService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isOpened) {
          this.menuService.toggle();
        }
      }
    });

    this.userService.me$.subscribe(me => {
      setTimeout(() => {
        this.me = me as User;
        this.initNotifications();
      }, 2000);
    });

    this.menuService.isOpened()
      .subscribe(_ => {
        this.isOpened = !this.isOpened;
      });
  }

  toggle(): void {
    this.menuService.toggle();
  }

  initNotifications(): void {
    if (this.authService.isLoggedIn()) {
      this.getNotifications();
      setInterval(this.getNotifications.bind(this), 5 * 60 * 1000);
    }
  }

  private getNotifications(): void {
    if (this.dialogRef) {
      return;
    }

    this.userService.getNotifications().subscribe(
      notifications => {
        if (!notifications || !notifications.length) {
          return;
        }

        const notification = notifications[0];

        this.dialogRef = this.dialog.open(ConfirmationModalComponent, {
          data: {
            title: `Você tem uma atualização em sua ${notification.movement}`,
            text: `Houve uma atualização n${notification.type === 0 ? 'o' : 'a'} ${notification.entityName}`,
            buttonLabel: 'ir para página',
            callback: () => {
              this.router.navigate([
                `/${
                  notification.type === 0 ?
                    (notification.movement === 'venda' ? 'sell' : 'buy') :
                    'transactions'
                  }/${notification.objId}`
              ]);
            },
            close: () => {
              this.userService.viewNotification(notification.id).subscribe();
              this.dialogRef = undefined;
            }
          }
        });
      }
    );
  }

}

<section class="initial top">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Informações adicionais</h2>
      <p>Agora precisamos de mais algumas informações.</p>

      <form [formGroup]="form" (ngSubmit)="save()">
        <mat-form-field>
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="leeq@teste.com" formControlName="email">
          <mat-error *ngIf="form.get('email')?.hasError('required')">
            E-mail é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="form.get('email')?.hasError('nonUniqueEmail')">
            E-mail já <strong>cadastrado</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Número de celular</mat-label>
          <input matInput placeholder="(11) 99999-9999" formControlName="phone" mask="(00) 00000-0000">
          <mat-error *ngIf="form.get('phone')?.hasError('required')">
            Número de celular é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <ng-container formGroupName="address">
          <mat-form-field>
            <mat-label>CEP</mat-label>
            <input matInput placeholder="00000-000" formControlName="zip" mask="00000-000">
            <mat-error *ngIf="form.get('address')?.get('zip')?.hasError('required')">
              CEP é <strong>obrigatório</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Logradouro</mat-label>
            <input matInput formControlName="street">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Bairro</mat-label>
            <input matInput formControlName="district">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input matInput placeholder="123" formControlName="number">
            <mat-error *ngIf="form.get('address')?.get('number')?.hasError('required')">
              Número é <strong>obrigatório</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Complemento</mat-label>
            <input matInput placeholder="Apto xxx" formControlName="complement">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Cidade</mat-label>
            <input matInput formControlName="city">
          </mat-form-field>
          <mat-form-field>
            <mat-label>UF</mat-label>
            <input matInput formControlName="province">
          </mat-form-field>
        </ng-container>
        <mat-form-field>
          <mat-label>Senha</mat-label>
          <input type="password" matInput formControlName="password">
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            Senha é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('minlength')">
            Sua senha deve conter no mínimo <strong>6 caracteres</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Confirmar senha</mat-label>
          <input type="password" matInput formControlName="retypePassword">
          <mat-error *ngIf="form.get('retypePassword')?.hasError('required')">
            Confirmar senha é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="form.get('retypePassword')?.hasError('mustMatch')">
            Senha e confirmação devem ser <strong>iguais</strong>
          </mat-error>
        </mat-form-field>
        <!-- <mat-checkbox [(ngModel)]="acceptTerms" [ngModelOptions]="{standalone: true}">Estou de acordo com os Termos de Uso e Política de Privacidade da Leeq.</mat-checkbox> -->
        <div class="buttons">
          <button class="btn btn-primary">enviar</button>
          <a (click)="back()" class="btn btn-secondary">voltar</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptService implements HttpInterceptor {

  constructor(private router: Router, private loading: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loading.push();

    let token = localStorage.getItem('token');

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        withCredentials: false
      });
    }

    return next.handle(request)
      .pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
              this.loading.pop();
              token = event.headers.get('Token');
              if (token) {
                localStorage.setItem('token', token);
              }
            }
          },
          error => {
            this.loading.pop();
            if ([401].includes(error.status)) {
              localStorage.removeItem('token');
              this.router.navigateByUrl('login');
              return;
            }
          }
        )
      );
  }
}

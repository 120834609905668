import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { MustMatch } from 'src/app/validators/must-match.validator';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  public form = this.formBuilder.group({
    password: ['', [Validators.minLength(6)]],
    retypePassword: ['', []],
  }, {
    validators: [MustMatch('password', 'retypePassword')]
  });

  public token!: string;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.h;

    this.authService.validResetToken(this.token).subscribe(
      response => {},
      error => {
        this.router.navigate(['/reset/error']);
      }
    );
  }

  save(): void {
    this.userService.resetPass(this.form.get('password')?.value, this.token)
      .subscribe(
        response => {
          this.dialog.open(ConfirmationModalComponent, {
            data: {
              title: 'Senha redefinida com sucesso',
              text: 'Sua senha foi redefinida com sucesso, clique no botão abaixo para ir pra tela de login',
              buttonLabel: 'ir para o login',
              close: () => {
                this.router.navigate(['/login']);
              }
            }
          });
        },
        error => this.router.navigate(['/reset/error'])
      );
  }

}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AcceptTermsComponent } from 'src/app/components/accept-terms/accept-terms.component';
import { User } from 'src/app/domain/User';
import { AddressService } from 'src/app/services/address.service';
import { UserService } from 'src/app/services/user.service';
import { MustMatch } from 'src/app/validators/must-match.validator';
import { UniqueEmail } from 'src/app/validators/unique-email.validator';
import { ZipCodeValidator } from 'src/app/validators/zipcode.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup-additional',
  templateUrl: './signup-additional.component.html',
  styleUrls: ['./signup-additional.component.scss']
})
export class SignupAdditionalComponent implements OnInit {

  public form = this.formBuilder.group({
    email: new FormControl(
      '',
      {
        validators: [Validators.required, Validators.email],
        asyncValidators: [UniqueEmail.createValidator(this.userService)],
        updateOn: 'blur'
      }
    ),
    phone: ['', [Validators.required]],
    address: this.formBuilder.group({
      zip: ['', [Validators.required, ZipCodeValidator]],
      street: [{ value: '', disabled: true }],
      district: [{ value: '', disabled: true }],
      number: ['', [Validators.required]],
      complement: [''],
      city: [{ value: '', disabled: true }],
      province: [{ value: '', disabled: true }],
    }),
    password: ['', [Validators.minLength(6)]],
    retypePassword: ['', []],
  }, {
    validators: [MustMatch('password', 'retypePassword')]
  });

  public user: User;

  public acceptTerms = false;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private addressService: AddressService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router) {
    this.user = this.router.getCurrentNavigation()?.extras?.state?.user;

    if (!environment.production && !this.user) {
      this.user = new User();
    }
  }

  ngOnInit(): void {
    if (!this.user) {
      this.router.navigateByUrl('/signup');
    }

    const zipControl = this.form.get('address')?.get('zip');

    if (zipControl) {
      zipControl.valueChanges.subscribe(value => this.loadAddress(zipControl, value));
    }

  }

  loadAddress(control: AbstractControl, zipcode: string): void {
    if (control.valid) {
      this.addressService.search(zipcode).subscribe(
        address => {
          const addressControl = this.form.get('address');

          addressControl?.get('street')?.setValue(address.street);
          addressControl?.get('district')?.setValue(address.district);
          addressControl?.get('city')?.setValue(address.city);
          addressControl?.get('province')?.setValue(address.province);
        }
      );
    }
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.dialog.open(AcceptTermsComponent, {
      data: {
        callback: () => {
          this.user = {...this.user, ...this.form.getRawValue()};
          this.userService.create(this.user).subscribe(
            _ => {
              this.router.navigateByUrl('/signup/success');
            },
            response => {
              this.snackBar.open(response.error.message || 'Erro ao cadastrar usuário!', 'OK');
            }
          );
        }
      }
    });

  }

  back(): void {
    this.router.navigateByUrl('/signup/confirmation', { state: { user: this.user } });
  }
}

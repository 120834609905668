<section class="home">
  <section class="sidebar" [class.opened]="isOpened">
    <a href="https://leeq.com.br/">
      <img src="../assets/images/logo-green.svg" alt="Logo Verde Plataforma Leeq" />
    </a>
    <mat-icon (click)="toggle()">close</mat-icon>
    <h3 class="welcome">Olá, {{ me?.firstName() }}</h3>
    <app-menu></app-menu>
  </section>
  <section class="content">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
  </section>
</section>

<app-footer></app-footer>
<app-full-loading [class.hidden]="me"></app-full-loading>

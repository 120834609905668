import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private authService: AuthService) { }

  ngOnInit(): void {
  }

  login(): void {
    if (!this.form.valid) {
      return;
    }

    this.authService.login(this.form.value)
      .then(_ => {
        window.location.pathname = '/';
      })
      .catch(error => {
        this.snackBar.open(error, 'OK', { duration: 5000 });
      });
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brCurrency'
})
export class BrCurrencyPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (!value) { return ''; }
    return `R$ ${value.toFixed(2).toString().replace(/\./, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
  }

}













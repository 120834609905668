import { AbstractControl } from '@angular/forms';

export function DocumentValidator(control: AbstractControl): {[key: string]: any} | null {
  if (!control.value) { return null; }

  let valid: boolean;

  switch (control.value.length) {
    case 11: valid = validateCPF(control.value); break;
    case 14: valid = validateCNPJ(control.value); break;
    default: valid = false;
  }

  return valid ? null : { documentinvalid: true };
}

function validateCPF(document: string): boolean {
  let amount;
  let rest;
  amount = 0;
  if (document === '00000000000') { return false; }

  for (let i = 1; i <= 9; i++) { amount = amount + parseInt(document.substring(i - 1, i), 10) * (11 - i); }
  rest = (amount * 10) % 11;

  if ((rest === 10) || (rest === 11)) {  rest = 0; }
  if (rest !== parseInt(document.substring(9, 10), 10) ) { return false; }

  amount = 0;
  for (let i = 1; i <= 10; i++) { amount = amount + parseInt(document.substring(i - 1, i), 10) * (12 - i); }
  rest = (amount * 10) % 11;

  if ((rest === 10) || (rest === 11)) {  rest = 0; }
  if (rest !== parseInt(document.substring(10, 11), 10) ) { return false; }
  return true;
}

function validateCNPJ(document: string): boolean {
  if (!document) { return false; }

  const validTypes =
    typeof document === 'string' || Number.isInteger(document) || Array.isArray(document);

  if (!validTypes) { return false; }

  const match = document.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 14) { return false; }

  const items = [...new Set(numbers)];
  if (items.length === 1) { return false; }

  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) { factor = 9; }
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  const digits = numbers.slice(12);

  const digit0 = calc(12);
  if (digit0 !== digits[0]) { return false; }

  const digit1 = calc(13);
  return digit1 === digits[1];
}

import { Component, OnInit } from '@angular/core';
import { Offer } from 'src/app/domain/Offer';
import { OfferService } from 'src/app/services/offer.service';

@Component({
  selector: 'app-admin-offer-list',
  templateUrl: './admin-offer-list.component.html',
  styleUrls: ['./admin-offer-list.component.scss']
})
export class AdminOfferListComponent implements OnInit {

  public offers: Offer[] = [];

  constructor(private offerService: OfferService) { }

  ngOnInit(): void {
    this.offerService.list().subscribe(offers => this.offers = offers);
  }

}

<!-- <h1>Política de Privacidade</h1> -->

<h1>Vamos a nossa política de privacidade.</h1>
<h1>Só vai levar alguns minutinhos e é muito importante que você leia.</h1>

<h3>Introdução</h3>
<p>
  A privacidade dos visitantes do nosso site é muito importante para nós, e estamos comprometidos em protegê-la. Esta política explica o que faremos com suas informações pessoais.
</p>
<p>
  Consentir com o uso de cookies de acordo com os termos desta política quando você acessa nosso site pela primeira vez nos permite usar cookies toda vez que você acessa nosso site.
</p>

<h3>Coleta de informações pessoais</h3>
<p>
  Os seguintes tipos de informações pessoais podem ser coletados, armazenados e usados: informações sobre o seu computador, incluindo seu endereço IP, localização geográfica, tipo e versão do navegador e sistema operacional; informações sobre suas visitas e uso deste site, incluindo fonte de referência, duração da visita, visualizações de página e caminhos de navegação no site; informações, como seu endereço de e-mail, que você digita quando se registra em nosso site; informações que você digita ao criar um perfil em nosso site – por exemplo, seu nome, CPF, CNPJ, data de nascimento, endereço, e informações gerais; informações, como seu nome e endereço de e-mail, que você digita para configurar assinaturas de nossos e-mails e/ou newsletters; informações que você digita durante o uso dos serviços em nosso site; informações geradas ao usar nosso site, incluindo quando, com que frequência e em que circunstâncias você o utiliza; informações relacionadas a tudo o que você compra, serviços que usa ou transações que realiza através do nosso site, incluindo nome, endereço, número de telefone, endereço de e-mail e dados bancários; informações que você cadastra em nosso site, incluindo seu nome de usuário, números de processos e o conteúdo cadastrado informações contidas em quaisquer comunicações que você nos envia por e-mail ou através de nosso site, incluindo o conteúdo e os metadados da comunicação; qualquer outra informação pessoal que você nos enviar.
</p>

<h1>Muito bem, estamos gostando de ver você lendo com atenção, continue.</h1>

<h3>Uso de suas informações pessoais</h3>
<p>
  As informações pessoais que nos são enviadas por meio de nosso site serão usadas para os fins especificados nesta política ou nas páginas relevantes do site. Podemos usar suas informações pessoais para o seguinte: administrar nosso site e nossos negócios; personalizar nosso site para você; possibilitar o uso dos serviços disponíveis em nosso site; prestar serviços adquiridos através do nosso site; enviar extratos, faturas e lembretes de pagamento, bem como coletar seus pagamentos; enviar comunicações comerciais que não sejam de marketing; enviar notificações por e-mail solicitadas especificamente por você; enviar nossa newsletter por e-mail, caso você a tenha solicitado (você pode nos informar a qualquer momento se não quiser mais receber a newsletter); enviar comunicações de marketing relacionadas aos nossos negócios ou aos negócios de terceiros cuidadosamente selecionados que acreditamos ser do seu interesse, por correio ou, onde você especificamente concordou com isso, por e-mail ou tecnologia semelhante (você pode nos informar a qualquer momento se não mais quiser mais receber comunicações de marketing); fornecer a terceiros informações estatísticas sobre nossos usuários (mas esses terceiros não poderão identificar nenhum usuário individual a partir dessas informações); lidar com perguntas e reclamações feitas por você ou sobre você em relação ao nosso site; manter nosso site seguro e evitar fraudes; verificar a conformidade com os termos e condições que regem o uso do nosso site (incluindo o monitoramento de mensagens privadas enviadas por meio do serviço de mensagens privadas do nosso site); e outros usos.
</p>
<p>
  Suas configurações de privacidade podem ser usadas para limitar a publicação de suas informações em nosso site e ajustadas através do uso de controles de privacidade no site.
</p>
<p>
  Sem seu consentimento expresso, não forneceremos suas informações pessoais a terceiros para fins de marketing direto por parte deles ou de terceiros.
</p>

<h3>Divulgação de dados pessoais</h3>
<p>
  Podemos divulgar seus dados a qualquer um de nossos funcionários, executivos, seguradoras, consultores profissionais, agentes, fornecedores ou subcontratados conforme razoavelmente necessário para os fins estabelecidos nesta política.
</p>
<p>
  Podemos divulgar seus dados pessoais a qualquer membro de nosso grupo de empresas (isso significa nossas subsidiárias, nossa holding e todas as suas subsidiárias) conforme razoavelmente necessário para os fins estabelecidos nesta política.
</p>
<p>
  Ao cadastrar-se no site, você nos autoriza a divulgar seus dados pessoais na seguintes hipóteses: i) solicitação pelo comprador (ou comprador em potencial) acerca de qualquer negócio ou ativo que estejamos vendendo (ou contemplando vender); ii) a qualquer pessoa que acreditemos razoavelmente que possa solicitar a um tribunal ou outra autoridade competente a divulgação dessas informações pessoais, quando, em nossa opinião razoável, for provável que tal tribunal ou autoridade ordene a divulgação dessas informações pessoais.
</p>
<p>
  Os seus dados pessoais poderão, ainda, ser compartilhadas nos casos de: i) obrigação legal; ii) exigência em processo judicial em andamento ou potencial; iii) para estabelecer, exercer ou defender nossos direitos (incluindo fornecer informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito).
</p>
<p>
  Exceto conforme estabelecido nesta política, não forneceremos seus dados pessoais a terceiros.
</p>

<h1>Você já chegou na metade, falta só mais um pouquinho.</h1>

<h3>Transferências internacionais de dados</h3>
<p>
  As informações que coletamos podem ser armazenadas, processadas e transferidas entre qualquer um dos países em que operamos, a fim de nos permitir usar as informações de acordo com esta política.
</p>
<p>
  As informações que coletamos podem ser transferidas para os seguintes países que não possuem leis de proteção de dados equivalentes às vigentes no Espaço Econômico Europeu: Estados Unidos da América, Rússia, Japão, China e Índia.
</p>
<p>
  As informações pessoais que você publica em nosso site ou envia para publicação em nosso site podem estar disponíveis, através da internet, em todo o mundo. Não podemos impedir o uso ou uso indevido de tais informações por terceiros.
</p>
<p>
  Você concorda expressamente com as transferências de informações pessoais descritas nesta seção.
</p>

<h3>Retenção de informações pessoais</h3>
<p>
  Esta seção define nossas políticas e procedimentos de retenção de dados, projetados para ajudar a garantir o cumprimento de nossas obrigações legais em relação à retenção e exclusão de informações pessoais.
</p>
<p>
  As informações pessoais que processamos para qualquer propósito ou propósitos não devem ser mantidas por mais tempo do que o necessário para esse propósito ou propósitos.
</p>
<p>
  Não obstante as outras disposições desta seção, reteremos documentos (incluindo documentos eletrônicos) que contenham dados pessoais: na medida em que somos obrigados a fazê-lo por lei; se acreditarmos que os documentos podem ser relevantes para qualquer processo judicial em andamento ou potencial; e para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer informações a terceiros para fins de prevenção de fraudes e redução do risco de crédito).
</p>

<h1>Muito bem! vamos ler sobre a segurança das informações fornecidas.</h1>

<h3>Segurança de suas informações pessoais</h3>
<p>
  Tomaremos as devidas precauções técnicas e organizacionais para evitar a perda, mau uso ou alteração de suas informações pessoais.
</p>
<p>
  Armazenaremos todas as suas informações pessoais fornecidas em nossos servidores seguros (protegidos por senha e firewall).
</p>
<p>
  Todas as transações financeiras eletrônicas realizadas através do nosso site serão protegidas por tecnologia de criptografia.
</p>
<p>
  Você reconhece que a transmissão de informações pela internet é inerentemente insegura e que não podemos garantir a segurança dos dados enviados pela internet.
</p>
<p>
  Você é responsável por manter o sigilo da senha usada para acessar nosso site; não solicitaremos sua senha (exceto quando você fizer login em nosso site).
</p>

<h3>Alterações</h3>
<p>
  Podemos atualizar esta política periodicamente, através da publicação de uma nova versão em nosso site. Você deve verificar esta página ocasionalmente para garantir que compreende quaisquer alterações nesta política. Podemos notificá-lo sobre alterações nesta política por e-mail ou através do sistema de mensagens privadas em nosso site.
</p>

<h1>Vamos continuar? agora estamos quase acabando.</h1>

<h3>Seus direitos</h3>
<p>
  Você pode nos instruir a fornecer qualquer informação pessoal que detenhamos sobre você; o fornecimento dessas informações estará sujeito ao seguinte: pagamento de uma taxa; podemos reter as informações pessoais solicitadas na extensão permitida por lei.
</p>
<p>
  Você pode nos instruir a qualquer momento para não processar suas informações pessoais para fins de marketing.
</p>

<h3>Sites de terceiros</h3>
<p>
  Não temos controle sobre e não somos responsáveis pelas políticas e práticas de privacidade de terceiros.
</p>

<h3>Atualização de informações</h3>
<p>
  Informe-nos se as informações pessoais que mantemos sobre você precisam ser corrigidas ou atualizadas.
</p>

<h3>Cookies</h3>
<p>
  Nosso site usa cookies. Um cookie é um arquivo que contém um identificador (uma sequência de letras e números) que é enviado por um servidor da web para um navegador e armazenado pelo navegador. O identificador é então enviado de volta ao servidor toda vez que o navegador solicita uma página do servidor. Os cookies podem ser cookies “persistentes” ou cookies “de sessão”: um cookie persistente será armazenado por um navegador e permanecerá válido até a data de vencimento definida, a menos que seja excluído pelo usuário antes da data de vencimento; um cookie de sessão, por outro lado, expirará no final da sessão do usuário, quando o navegador for fechado. Os cookies normalmente não contêm nenhuma informação que identifique pessoalmente um usuário, mas as informações pessoais que armazenamos sobre você podem estar vinculadas às informações armazenadas e obtidas a partir de cookies.
</p>
<p>
  A maioria dos navegadores permite que você se recuse a aceitar cookies – por exemplo: no Internet Explorer (versão 10), você pode bloquear cookies usando as configurações disponíveis de cancelamento de manipulação de cookies clicando em “Ferramentas”, “Opções da Internet” “Privacidade” e “Avançado”; no Firefox (versão 24), você pode bloquear todos os cookies clicando em “Ferramentas”, “Opções”, “Privacidade”, selecionando “Usar configurações personalizadas para o histórico” no menu suspenso e desmarcando “Aceitar cookies de sites” ; e no Chrome (versão 29), você pode bloquear todos os cookies acessando o menu “Personalizar e controlar” e clicando em “Configurações”, “Avançado” e “Configurações do site” e, em seguida, selecionando “Bloquear cookies de terceiros” na seção “Cookies e dados do site”.
</p>
<p>
  O bloqueio de todos os cookies causará um impacto negativo na usabilidade de muitos sites. Se você bloquear os cookies, não poderá usar todos os recursos em nosso site.
</p>
<p>
  Você pode excluir os cookies que já estão armazenados no seu computador – por exemplo: no Internet Explorer (versão 10), você deve excluir os arquivos de cookies manualmente; no Firefox (versão 24), você pode excluir os cookies clicando em “Ferramentas”, “Opções”, “Privacidade”, selecionando “Usar configurações personalizadas para o histórico”, clicando em “Mostrar cookies” e, então, em “Remover todos os cookies”; e no Chrome (versão 29), você pode excluir todos os cookies acessando o menu “Personalizar e controlar” e clicando em “Configurações”, “Avançado”, “Limpar dados de navegação” e, em seguida, selecionando “Excluir cookies e outros dados de sites e plugins” antes de clicar em “Limpar dados de navegação”.
</p>
<p>
  Caso você não queira mais que seja possível para nós coletar as suas informações pessoais, e se você tiver quaisquer dúvidas sobre esta Política de Privacidade, ou queira acessar, corrigir ou deletar qualquer informação que tenhamos coletado sobre você, fique à vontade para contatar-nos pelo e-mail contato@leeq.com.br.
</p>

<h1>Parabéns! você concluiu a leitura da política de privacidade na nossa empresa.</h1>
<h1>Agora é só usar a plataforma e entrar de vez no mundo dos precatórios.</h1>

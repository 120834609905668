<section class="buy">
  <div class="title">
      <h2>Transações</h2>
      <p>Veja abaixo todas as suas transações.</p>
  </div>

  <section class="list">
    <h3>Transações</h3>
    <ul class="table">
      <li class="header">
        <span>Número</span>
        <span>Valor total</span>
        <span>Valor da oferta</span>
        <span>Status</span>
        <span></span>
      </li>
      <li *ngFor="let item of transactions">
        <span>{{ item.precatory.number }}</span>
        <span>{{ item.precatory.amount | brCurrency }}</span>
        <span>{{ item.offer.amount | brCurrency }}</span>
        <span>{{ item.statusLabel }}</span>
        <span class="actions">
          <a *ngIf="!me?.isAdmin()" [routerLink]="['/transactions/' + item.id]" class="btn btn-primary">ver</a>
          <a *ngIf="me?.isAdmin()" [routerLink]="['/admin/transactions/' + item.id]" class="btn btn-primary">ver</a>
        </span>
        <!-- <span class="actions">
          <a *ngIf="!item.hasOffer()" [routerLink]="['/buy/' + item.id]" class="btn btn-primary">fazer oferta</a>
          <a *ngIf="item.hasOffer()" [routerLink]="['/buy/' + item.id]" class="btn btn-ghost status-{{item.getOfferStatus().value}}">{{ item.getOfferStatus().label.toLowerCase() }}</a>
        </span> -->
      </li>
    </ul>
    <div class="empty" *ngIf="!transactions || !transactions.length">Não encontramos nenhuma transação.</div>
  </section>

</section>


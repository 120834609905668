import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/domain/User';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html',
  styleUrls: ['./signup-confirmation.component.scss']
})
export class SignupConfirmationComponent implements OnInit {

  public user: User;

  constructor(private router: Router) {
    this.user = this.router.getCurrentNavigation()?.extras?.state?.user;

    if (!environment.production && !this.user) {
      // this.user = {
      //   id: 1,
      //   name: 'João Teste',
      //   document: '12345678909',
      //   date: new Date(1990, 1, 1),
      //   type: 1
      // };
    }
  }

  ngOnInit(): void {
    if (!this.user) {
      this.router.navigateByUrl('/signup');
    }
  }

  next(): void {
    this.router.navigateByUrl('/signup/additional', { state: { user: this.user } });
  }

}

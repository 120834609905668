import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { AccountConfirmationComponent } from './screens/account/account-confirmation/account-confirmation.component';
import { AccountErrorComponent } from './screens/account/account-error/account-error.component';
import { AdminOfferListComponent } from './screens/admin/admin-offer-list/admin-offer-list.component';
import { AdminPrecatoryDetailsComponent } from './screens/admin/admin-precatory-details/admin-precatory-details.component';
import { AdminPrecatoryListComponent } from './screens/admin/admin-precatory-list/admin-precatory-list.component';
import { AdminUserListComponent } from './screens/admin/admin-user-list/admin-user-list.component';
import { BuyComponent } from './screens/buy/buy.component';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { ForgotPassComponent } from './screens/forgot-pass/forgot-pass.component';
import { ResetPassErrorComponent } from './screens/forgot-pass/reset-pass/reset-pass-error/reset-pass-error.component';
import { ResetPassComponent } from './screens/forgot-pass/reset-pass/reset-pass.component';
import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/login/login.component';
import { MakeOfferComponent } from './screens/offer/make-offer/make-offer.component';
import { OffersListComponent } from './screens/offer/offers-list/offers-list.component';
import { NewPrecatoryComponent } from './screens/sell/new-precatory/new-precatory.component';
import { SellComponent } from './screens/sell/sell.component';
import { SignupAdditionalComponent } from './screens/signup/signup-additional/signup-additional.component';
import { SignupConfirmationComponent } from './screens/signup/signup-confirmation/signup-confirmation.component';
import { SignupDocumentComponent } from './screens/signup/signup-document/signup-document.component';
import { SignupSuccessComponent } from './screens/signup/signup-success/signup-success.component';
import { SignupComponent } from './screens/signup/signup.component';
import { TransactionDetailsComponent } from './screens/transaction/transaction-details/transaction-details.component';
import { TransactionsListComponent } from './screens/transaction/transactions-list/transactions-list.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotPassComponent },
  {
    path: 'reset',
    children: [
      { path: '', pathMatch: 'full', component: ResetPassComponent },
      { path: 'error', component: ResetPassErrorComponent },
    ]
  },
  {
    path: 'signup',
    component: SignupComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'document' },
      { path: 'document', component: SignupDocumentComponent },
      { path: 'confirmation', component: SignupConfirmationComponent },
      { path: 'additional', component: SignupAdditionalComponent },
      { path: 'success', component: SignupSuccessComponent },
    ]
  },
  {
    path: 'account',
    children: [
      { path: 'confirmation', component: AccountConfirmationComponent },
      { path: 'error', component: AccountErrorComponent },
    ]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [ AuthGuard ],
    children: [
      { path: '', pathMatch: 'full', component: DashboardComponent },
      { path: 'buy', children: [
        { path: '', pathMatch: 'full', component: BuyComponent },
        { path: ':id', component: MakeOfferComponent }
      ]},
      {
        path: 'sell', children: [
          { path: '', pathMatch: 'full', component: SellComponent },
          { path: 'new', pathMatch: 'full', component: NewPrecatoryComponent },
          { path: ':id', component: OffersListComponent },
          { path: ':id/edit', component: NewPrecatoryComponent },
        ]
      },
      {
        path: 'transactions', children: [
          { path: '', pathMatch: 'full', component: TransactionsListComponent },
          { path: ':id', component: TransactionDetailsComponent }
        ]
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        children: [
          { path: 'users', component: AdminUserListComponent },
          { path: 'precatories', pathMatch: 'full', component: AdminPrecatoryListComponent },
          { path: 'precatories/:id', component: AdminPrecatoryDetailsComponent },
          { path: 'offers', component: AdminOfferListComponent },
          { path: 'transactions', pathMatch: 'full', component: TransactionsListComponent },
          { path: 'transactions/:id', component: TransactionDetailsComponent },
          { path: '**', redirectTo: '/' }
        ]
      },
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { IPrecatoryResponse } from './IPrecatoryResponse';
import { Offer, OfferStatus } from './Offer';
import { Transaction } from './Transaction';
import { User } from './User';

export class Precatory {
  id!: number;
  number!: string;
  budgetYear!: number;

  processType!: ProcessType;
  precatoryType!: PrecatoryType;
  creditNature!: CreditNature;
  creditorType!: CreditorType;
  status!: PrecatoryStatus;

  processTypeLabel!: string;
  precatoryTypeLabel!: string;
  creditNatureLabel!: string;
  creditorTypeLabel!: string;
  statusLabel!: string;

  processNumber!: string;
  craft!: string;
  contract!: string;
  procuration!: string;
  amount!: number;
  discount!: number;
  amountWithDiscount!: number;

  offers?: Offer[];
  transaction?: Transaction;

  partners!: {name: string, email: string, cpf: string}[];

  currentOwner!: User;

  static fromVO(vo: IPrecatoryResponse): Precatory {
    const p = new Precatory();

    p.id = vo.id;
    p.number = vo.number;
    p.budgetYear = vo.budgetYear;
    p.processType = vo.processType as ProcessType;
    p.precatoryType = vo.precatoryType as PrecatoryType;
    p.creditNature = vo.creditNature as CreditNature;
    p.creditorType = vo.creditorType as CreditorType;
    p.status = vo.status as PrecatoryStatus;

    p.processTypeLabel = ProcessType.get(p.processType);
    p.precatoryTypeLabel = PrecatoryType.get(p.precatoryType);
    p.creditNatureLabel = CreditNature.get(p.creditNature);
    p.creditorTypeLabel = CreditorType.get(p.creditorType);
    p.statusLabel = PrecatoryStatus.get(p.status);

    p.processNumber = vo.processNumber;
    p.amount = parseFloat(vo.amount);
    p.discount = vo.discount;
    p.amountWithDiscount = p.getAmountWithDiscount();

    p.craft = vo.craft;
    p.contract = vo.contract;
    p.procuration = vo.procuration;

    p.partners = vo.partners?.map((partner: any) => {
      return {
        name: partner.name,
        email: partner.email,
        cpf: partner.cpf
      };
    });

    p.offers = vo.offers?.map(offer => Offer.fromVO(offer));

    if (vo.transaction) {
      p.transaction = Transaction.fromVO(vo.transaction);
    }

    if (vo.currentOwner) {
      p.currentOwner = User.fromVO(vo.currentOwner);
    }

    return p;
  }

  toForm(): object {
    return {
      number: this.number,
      budgetYear: this.budgetYear,
      processType: this.processType,
      precatoryType: this.precatoryType,
      creditNature: this.creditNature,
      creditorType: this.creditorType,
      processNumber: this.processNumber,
      amount: this.amount,
      discount: this.discount,
      craft: '',
      contract: '',
      procuration: '',
      partners: this.partners,
    };
  }

  getAmountWithDiscount(): number {
    return this.amount * (1.0 - (this.discount / 100.0));
  }

  hasOffer(): boolean {
    return !!this.offers?.length;
  }

  canOffer(): boolean {
    return !!this.offers?.every(o => o.status === OfferStatus.REJECTED);
  }

  getOfferStatus(): {value: OfferStatus, label: string} {
    const stati = this.offers?.map(o => o.status);
    const status = stati?.reduce((a, b) => Math.max(a, b));

    return {
      value: status as OfferStatus,
      label: OfferStatus.get(status as OfferStatus)
    };
  }

  isCanceled(): boolean {
    return this.status === PrecatoryStatus.CANCELED;
  }
}

export enum ProcessType {
  PHYSICAL = 1, ELECTRONIC
}

// tslint:disable-next-line:no-namespace
export namespace ProcessType {
  export function get(type: ProcessType): string {
    switch (type) {
      case ProcessType.PHYSICAL: return 'Físico';
      case ProcessType.ELECTRONIC: return 'Eletrônico';
    }
  }
}

export enum PrecatoryType {
  FEDERAL = 1, STATE, MUNICIPAL
}

// tslint:disable-next-line:no-namespace
export namespace PrecatoryType {
  export function get(type: PrecatoryType): string {
    switch (type) {
      case PrecatoryType.FEDERAL: return 'Federal';
      case PrecatoryType.STATE: return 'Estadual';
      case PrecatoryType.MUNICIPAL: return 'Municipal';
    }
  }
}

export enum CreditNature {
  COMMON = 1, NURTURE
}

// tslint:disable-next-line:no-namespace
export namespace CreditNature {
  export function get(type: CreditNature): string {
    switch (type) {
      case CreditNature.COMMON: return 'Comum';
      case CreditNature.NURTURE: return 'Alimentar';
    }
  }
}

export enum CreditorType {
  ORIGINAL = 1, HEIR, ASSIGNEE, ATTORNEY
}

// tslint:disable-next-line:no-namespace
export namespace CreditorType {
  export function get(type: CreditorType): string {
    switch (type) {
      case CreditorType.ORIGINAL: return 'Credor originário';
      case CreditorType.HEIR: return 'Herdeiro';
      case CreditorType.ASSIGNEE: return 'Cessionário';
      case CreditorType.ATTORNEY: return 'Procurador';
    }
  }
}

export enum PrecatoryStatus {
  DISAPPROVED, ANALYSIS = 1, APPROVED, IN_TRANSACTION, SELLED, CANCELED
}

// tslint:disable-next-line:no-namespace
export namespace PrecatoryStatus {
  export function get(type: PrecatoryStatus): string {
    switch (type) {
      case PrecatoryStatus.DISAPPROVED: return 'Reprovado';
      case PrecatoryStatus.ANALYSIS: return 'Em análise';
      case PrecatoryStatus.APPROVED: return 'Aprovado';
      case PrecatoryStatus.IN_TRANSACTION: return 'Em transação';
      case PrecatoryStatus.SELLED: return 'Transação finalizada';
      case PrecatoryStatus.CANCELED: return 'Transação cancelada';
    }
  }
}

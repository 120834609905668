<section class="initial">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Redefina sua senha</h2>
      <p>Crie uma nova senha.</p>

      <form [formGroup]="form" (ngSubmit)="save()">
        <mat-form-field>
          <mat-label>Nova senha</mat-label>
          <input type="password" matInput formControlName="password">
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            Nova senha é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('minlength')">
            Sua nova senha deve conter no mínimo <strong>6 caracteres</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Confirmar nova senha</mat-label>
          <input type="password" matInput formControlName="retypePassword">
          <mat-error *ngIf="form.get('retypePassword')?.hasError('required')">
            Confirmar nova senha é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="form.get('retypePassword')?.hasError('mustMatch')">
            Os campos de senha devem ser <strong>iguais</strong>
          </mat-error>
        </mat-form-field>
        <div class="buttons">
          <button class="btn btn-primary">enviar</button>
          <a [routerLink]="['/login']" class="btn btn-secondary">voltar</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

<section class="initial">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Seu e-mail foi verificado</h2>
      <p>Agora você pode comprar e vender precatórios dentro da nossa plataforma.</p>
      <form>
        <div class="buttons">
          <a href="/" class="btn btn-primary">ir para tela inicial</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

<mat-icon (click)="close()" class="close">close</mat-icon>
<h2>Elaboração do contrato</h2>
<p>Faça as edições necessárias e disponibilize para assinatura</p>
<form [formGroup]="form" (ngSubmit)="save()">
  <angular-editor formControlName="contract" [config]="editorConfig"></angular-editor>
  <button class="btn btn-primary">
    <ng-container *ngIf="!loading">salvar contrato</ng-container>
    <mat-spinner *ngIf="loading" [diameter]="18"></mat-spinner>
  </button>
</form>

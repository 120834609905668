import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IAuthRequest } from 'src/app/domain/IAuthRequest';
import { IAuthResponse } from 'src/app/domain/IAuthResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  async login(payload: IAuthRequest): Promise<void> {
    const promise = new Promise<void>(async (resolve, reject) => {
      try {
        const response = await this.http.post<IAuthResponse>(
            `${environment.apiUrlBase}/auth/login`,
            payload,
            { observe: 'response' }
          ).toPromise();

        if (!response.body) {
          reject('Ocorreu um erro. Tente novamente mais tarde');
          return;
        }

        localStorage.setItem('token', response.body.token);
        resolve();

      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 401) {
          reject('E-mail ou senha inválidos');
        } else if (response.status === 403) {
          reject('Por favor verifique sua caixa de e-mail e confirme sua conta');
        } else {
          reject('Ocorreu um erro. Tente novamente mais tarde');
        }
      }
    });

    return promise;
  }

  loginByToken(token: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/auth/authorize`, { token });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  logout(): void {
    return localStorage.removeItem('token');
  }

  forgotPass(payload: { email: string }): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/auth/reset`, payload);
  }

  validResetToken(token: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrlBase}/auth/reset/valid`, { token });
  }
}

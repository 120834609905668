<section class="new_precatory">
  <div class="title">
    <h2>{{ precatoryId ? 'Editar' : 'Cadastrar' }} precatório</h2>
    <p>Aqui você vai encontrar as melhores oportunidades do mercado para aquisição e venda de créditos.</p>
  </div>
  <form [formGroup]="form">
    <div class="step">
      <span class="current">{{ (stepper?.selectedIndex || 0) + 1 }}</span>
      &nbsp;de&nbsp;
      <span class="total">{{ stepper?.steps ? stepper?.steps?.length : 0 }}</span>
    </div>
    <div fxLayoutAlign="center center" fxLayout="column" class="content">
      <mat-horizontal-stepper labelPosition="bottom" #stepper linear interacted [@.disabled]="true">
        <mat-step [stepControl]="getControl('precatoryType')">
          <h3>Em qual esfera o seu precatório pertence?</h3>
          <mat-radio-group formControlName="precatoryType">
            <mat-radio-button [value]="1">Federal</mat-radio-button>
            <mat-radio-button [value]="2">Estadual</mat-radio-button>
            <mat-radio-button [value]="3">Municipal</mat-radio-button>
          </mat-radio-group>
        </mat-step>
        <mat-step [stepControl]="getControl('processType')">
          <h3>O seu processo é físico ou eletrônico?</h3>
          <mat-radio-group formControlName="processType">
            <mat-radio-button [value]="1">Físico</mat-radio-button>
            <mat-radio-button [value]="2">Eletrônico</mat-radio-button>
          </mat-radio-group>
        </mat-step>
        <mat-step [stepControl]="getControl('number')">
          <h3>Vamos começar? Digite abaixo o número do seu precatório.</h3>
          <mat-form-field>
            <mat-label>Número do precatório</mat-label>
            <input matInput placeholder="NNNNNNN-DD.AAAA.J.TR.OOOO" required minlength="19"
              formControlName="number" mask="0000000-00.0000.0.00.000">
            <mat-error *ngIf="form.get('number')?.hasError('required')">
              Formato de precatório <strong>inválido</strong>
            </mat-error>
          </mat-form-field>
        </mat-step>
        <mat-step [stepControl]="getControl('budgetYear')">
          <h3>Agora selecione o ano orçamentário</h3>
          <mat-form-field>
            <mat-label>Ano orçamentário</mat-label>
            <select matNativeControl required formControlName="budgetYear">
              <option *ngFor="let i of yearList()" value="{{ i }}">{{ i }}</option>
            </select>
          </mat-form-field>
        </mat-step>
        <mat-step [stepControl]="getControl('creditNature')">
          <h3>Selecione a natureza do crédito</h3>
          <mat-radio-group formControlName="creditNature">
            <mat-radio-button [value]="1">Comum</mat-radio-button>
            <mat-radio-button [value]="2">Alimentar</mat-radio-button>
          </mat-radio-group>
        </mat-step>
        <mat-step [stepControl]="getControl('creditorType')">
          <h3>Legal, agora que tipo de credor você é?</h3>
          <mat-radio-group formControlName="creditorType" (change)="onCreditorTypeChange()">
            <mat-radio-button [value]="1">Credor originário</mat-radio-button>
            <mat-radio-button [value]="2">Herdeiro</mat-radio-button>
            <mat-radio-button [value]="3">Cessionário</mat-radio-button>
            <mat-radio-button *ngIf="me?.isCPF()" [value]="4">Procurador</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="getControl('creditorType')?.value === 4">
            <mat-form-field>
              <mat-label>Anexar procuração (PDF)</mat-label>
              <input type="text" matInput [readonly]="true" formControlName="procuration">
              <input type="file" accept="application/pdf" #procurationInput hidden (change)="onProcurationChange($event)">
            </mat-form-field>
            <a (click)="procurationInput.click()" class="btn btn-secondary">selecionar</a>
          </div>
          <p *ngIf="getControl('creditorType')?.value === 4">O seu arquivo está em PDF? Se não estiver, <a href="https://www.ilovepdf.com/" target="_blank">clique aqui</a> (ilovepdf.com) para transformá-lo antes de anexar.</p>
        </mat-step>
        <mat-step [stepControl]="getControl('processNumber')">
          <h3>Digite abaixo o número do processo que originou o seu precatório. (Aquele número gerado quando você entrou com a ação).</h3>
          <mat-form-field>
            <mat-label>Número do processo</mat-label>
            <input matInput placeholder="123.123.123.123" formControlName="processNumber">
          </mat-form-field>
        </mat-step>
        <mat-step [stepControl]="getControl('craft')">
          <h3>Estamos quase lá, agora preciso que você anexe aqui a cópia do ofício requisitório. (Aquele documento que deferiu o precatório).</h3>
          <div>
            <mat-form-field>
              <mat-label>Ofício requisitório</mat-label>
              <input type="text" matInput [readonly]="true" formControlName="craft">
              <input type="file" accept="application/pdf" #craftInput hidden (change)="onCraftChange($event)">
            </mat-form-field>
            <a (click)="craftInput.click()" class="btn btn-secondary">selecionar</a>
          </div>
          <p>O seu arquivo está em PDF? Se não estiver, <a href="https://www.ilovepdf.com/" target="_blank">clique aqui</a> (ilovepdf.com) para transformá-lo antes de anexar.</p>
        </mat-step>
        <mat-step [stepControl]="getControl('amount')">
          <h3>Qual é o valor do seu crédito?</h3>
          <mat-form-field>
            <mat-label>Valor total</mat-label>
            <input matInput formControlName="amount" currencyMask>
          </mat-form-field>
        </mat-step>
        <mat-step [stepControl]="getControl('discount')">
          <h3>Qual deságio você pretende aceitar? Se o deságio é de 30%, significa que será pago 70% do crédito.</h3>
          <mat-slider thumbLabel [displayWith]="formatDiscountLabel" formControlName="discount" min="0" max="100" step="1" value="0"></mat-slider>
          <mat-form-field>
            <mat-label>Valor com deságio</mat-label>
            <input matInput disabled type="text" value="{{ calcDiscount() | brCurrency }}" />
          </mat-form-field>
        </mat-step>
        <mat-step *ngIf="me?.isCNPJ()">
          <h3>Chegamos a última etapa, você possui algum sócio?</h3>
          <mat-radio-group [(ngModel)]="hasPartner" (change)="changePartner($event)" [ngModelOptions]="{standalone: true}">
            <mat-radio-button [value]="false">Não tenho sócio</mat-radio-button>
            <mat-radio-button [value]="true">Tenho sócio</mat-radio-button>
          </mat-radio-group>

          <ng-container *ngFor="let control of getPartnersControls()">
            <ng-container [formGroup]="control">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Rodrigo Freitas" formControlName="name">
              </mat-form-field>
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="rodrigofreitas@teste.com" formControlName="email">
              </mat-form-field>
              <mat-form-field>
                <mat-label>CPF</mat-label>
                <input matInput placeholder="000.000.000-00" mask="000.000.000-00" formControlName="cpf">
              </mat-form-field>
            </ng-container>
          </ng-container>

          <div *ngIf="hasPartner" class="include">
            <a (click)="addPartner()">+ incluir sócio</a>
          </div>

          <div>
            <mat-form-field>
              <mat-label>Anexar contrato social</mat-label>
              <input type="text" matInput [readonly]="true" formControlName="contract">
              <input type="file" accept="application/pdf" #contract hidden (change)="onContractChange($event)">
            </mat-form-field>
            <a (click)="contract.click()" class="btn btn-secondary">selecionar</a>
          </div>
          <p>O seu arquivo está em PDF? Se não estiver, <a href="https://www.ilovepdf.com/" target="_blank">clique aqui</a> (ilovepdf.com) para transformá-lo antes de anexar.</p>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="error" [class.hidden]="!stepper?.selected?.hasError">
      Hey, esse campo é obrigatório!
    </div>
    <div class="buttons">
      <a [class.disabled]="isFirst()" (click)="back()" class="btn btn-secondary">voltar</a>
      <a (click)="next()" class="btn btn-primary">{{ isLast() ? 'concluir' : 'continuar' }}</a>
    </div>
  </form>
</section>

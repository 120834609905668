import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './screens/login/login.component';
import { ForgotPassComponent } from './screens/forgot-pass/forgot-pass.component';
import { SignupComponent } from './screens/signup/signup.component';
import { SignupDocumentComponent } from './screens/signup/signup-document/signup-document.component';
import { SignupConfirmationComponent } from './screens/signup/signup-confirmation/signup-confirmation.component';
import { SignupAdditionalComponent } from './screens/signup/signup-additional/signup-additional.component';
import { SignupSuccessComponent } from './screens/signup/signup-success/signup-success.component';
import { HttpInterceptService } from './services/http-intercept.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorStateMatcher, MatNativeDateModule, MAT_DATE_LOCALE, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HomeComponent } from './screens/home/home.component';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SellComponent } from './screens/sell/sell.component';
import { NewPrecatoryComponent } from './screens/sell/new-precatory/new-precatory.component';
import { AccountConfirmationComponent } from './screens/account/account-confirmation/account-confirmation.component';
import { AccountErrorComponent } from './screens/account/account-error/account-error.component';
import localeBR from '@angular/common/locales/br';
import { BuyComponent } from './screens/buy/buy.component';
import { LoadingComponent } from './components/loading/loading.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HeaderComponent } from './components/header/header.component';
import { BrCurrencyPipe } from './pipes/br-currency.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MakeOfferComponent } from './screens/offer/make-offer/make-offer.component';
import { OffersListComponent } from './screens/offer/offers-list/offers-list.component';
import { PrecatoryDetailsComponent } from './components/precatory-details/precatory-details.component';
import { ResetPassComponent } from './screens/forgot-pass/reset-pass/reset-pass.component';
import { ResetPassErrorComponent } from './screens/forgot-pass/reset-pass/reset-pass-error/reset-pass-error.component';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { AdminPrecatoryListComponent } from './screens/admin/admin-precatory-list/admin-precatory-list.component';
import { AdminUserListComponent } from './screens/admin/admin-user-list/admin-user-list.component';
import { AdminPrecatoryDetailsComponent } from './screens/admin/admin-precatory-details/admin-precatory-details.component';
import { DocumentPipe } from './pipes/document.pipe';
import { AdminOfferListComponent } from './screens/admin/admin-offer-list/admin-offer-list.component';
import { TransactionsListComponent } from './screens/transaction/transactions-list/transactions-list.component';
import { TransactionDetailsComponent } from './screens/transaction/transaction-details/transaction-details.component';
import { ContractDraftingComponent } from './components/contract-drafting/contract-drafting.component';
import { SignatureComponent } from './components/signature/signature.component';
import { PaymentComponent } from './components/payment/payment.component';
import { FinalizeTransactionComponent } from './components/finalize-transaction/finalize-transaction.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { AcceptTermsComponent } from './components/accept-terms/accept-terms.component';
import { BuyerContractComponent } from './components/buyer-contract/buyer-contract.component';
import { SellerContractComponent } from './components/seller-contract/seller-contract.component';
import { ContractModalContainerComponent } from './components/contract-modal-container/contract-modal-container.component';
import { FullLoadingComponent } from './components/full-loading/full-loading.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const currencyConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.'
};

registerLocaleData(localeBR);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPassComponent,
    SignupComponent,
    SignupDocumentComponent,
    SignupConfirmationComponent,
    SignupAdditionalComponent,
    SignupSuccessComponent,
    HomeComponent,
    DashboardComponent,
    FooterComponent,
    MenuComponent,
    BreadcrumbComponent,
    SellComponent,
    NewPrecatoryComponent,
    AccountConfirmationComponent,
    AccountErrorComponent,
    BuyComponent,
    LoadingComponent,
    HeaderComponent,
    BrCurrencyPipe,
    ConfirmationModalComponent,
    MakeOfferComponent,
    OffersListComponent,
    PrecatoryDetailsComponent,
    ResetPassComponent,
    ResetPassErrorComponent,
    AdminPrecatoryListComponent,
    AdminUserListComponent,
    AdminPrecatoryDetailsComponent,
    DocumentPipe,
    AdminOfferListComponent,
    TransactionsListComponent,
    TransactionDetailsComponent,
    ContractDraftingComponent,
    SignatureComponent,
    PaymentComponent,
    FinalizeTransactionComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    AcceptTermsComponent,
    BuyerContractComponent,
    SellerContractComponent,
    ContractModalContainerComponent,
    FullLoadingComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatStepperModule,
    MatRadioModule,
    MatIconModule,
    MatSliderModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(maskConfig),
    AngularEditorModule
  ],
  providers: [
    HttpInterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptService,
      multi: true
    },
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: LOCALE_ID, useValue: 'br' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 10000, verticalPosition: 'bottom', horizontalPosition: 'center' }
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: currencyConfig },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

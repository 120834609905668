<section>
  <div class="title">
    <h2>Detalhe da sua transação</h2>
    <p>Confira abaixo todas as informações da sua transação ;)</p>
  </div>

  <section class="list" *ngIf="transaction">
    <h3>Sua transação</h3>
    <ul class="offer-details">
      <li>
        <p class="title">Dia da oferta</p>
        <p>{{ transaction.offer.date | date:'dd/MM/YYYY' }}</p>
      </li>
      <li>
        <p class="title">Valor da oferta</p>
        <p>{{ transaction.offer.amount | brCurrency }}</p>
      </li>
      <li *ngIf="showBuyerAmount()">
        <p class="title">Valor a ser pago</p>
        <p>{{ transaction.offer.buyerAmount | brCurrency }} ({{ transaction.offer.buyerComission | number }}%)</p>
      </li>
      <li *ngIf="showSellerAmount()">
        <p class="title">Valor a receber</p>
        <p>{{ transaction.offer.sellerAmount | brCurrency }} ({{ transaction.offer.sellerComission | number }}%)</p>
      </li>
      <li [ngSwitch]="transaction.status">
        <p class="title">Status</p>
        <p *ngSwitchCase="0">Essa transação foi cancelada pelo administrador.</p>
        <p *ngSwitchCase="1">Estamos preparando o contrato, em breve ele estará disponível para assinatura.</p>
        <p *ngSwitchCase="2">O contrato está pronto para ser assinado.</p>
        <p *ngSwitchCase="3">Aguardando pagamento para finalizar a venda.</p>
        <p *ngSwitchCase="4">Aguarde enquanto o pagamento está sendo conferido.</p>
        <p *ngSwitchCase="5">O pagamento foi efetuado e o contrato está disponível para download.</p>
      </li>
    </ul>
    <div class="transaction-steps">
      <ul>
        <li [class.done]="transaction.status > 1" [class.current]="transaction.status === 1">
          <div class="contract"></div>
          <span>Contrato em elaboração</span>
        </li>
        <li [class.done]="transaction.status > 2" [class.current]="transaction.status === 2">
          <div class="assign"></div>
          <span>Assinatura do contrato</span>
        </li>
        <li [class.done]="transaction.status > 3" [class.current]="transaction.status === 3">
          <div class="payment"></div>
          <span>Pagamento</span>
        </li>
        <li [class.current]="transaction.status === 4" [class.done]="transaction.status === 5">
          <div class="done"></div>
          <span>Transação finalizada</span>
        </li>
      </ul>
    </div>
    <div *ngIf="me?.isAdmin()" class="actions" [ngSwitch]="transaction.status">
      <button class="btn btn-ghost-danger" (click)="cancel()" *ngIf="transaction.status && transaction.status != 5">cancelar transação</button>
      <button class="btn btn-primary" (click)="editContract()" *ngSwitchCase="1">elaborar contrato</button>
      <button class="btn btn-ghost" (click)="rejectPayment()" *ngSwitchCase="4">reprovar pagamento</button>
      <button class="btn btn-primary" (click)="approvePayment()" *ngSwitchCase="4">aprovar pagamento</button>
    </div>
    <div *ngIf="!me?.isAdmin()" class="actions" [ngSwitch]="transaction.status">
      <ng-container *ngSwitchCase="2">
        <button class="btn btn-primary" *ngIf="!alreadySign()" (click)="sign()">assinar contrato</button>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <button class="btn btn-primary" *ngIf="isBuyer()" (click)="pay()">fazer pagamento via pix</button>
      </ng-container>
      <button class="btn btn-primary" *ngSwitchCase="5" (click)="download()">download do contrato</button>
    </div>
    <app-precatory-details [precatory]="transaction.precatory" [transaction]="transaction"></app-precatory-details>
  </section>

  <div class="back">
    <a href="javascript:history.back()" class="btn btn-primary">voltar</a>
  </div>

</section>

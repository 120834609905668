<section class="initial">
  <div class="content">
    <img src="../assets/images/logo.svg" alt="Logo Plataforma Leeq" />

    <div>
      <h2>Seja bem-vindo</h2>
      <p>Digite seus dados para criar sua conta.</p>

      <form [formGroup]="form" (ngSubmit)="next()">
        <mat-form-field>
          <mat-label>CPF ou CNPJ</mat-label>
          <input matInput placeholder="123.456.789-09" formControlName="document" mask="000.000.000-00||00.000.000/0000-00">
          <mat-error *ngIf="form.get('document')?.hasError('required')">
            CPF ou CNPJ é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="form.get('document')?.hasError('documentinvalid')">
            CPF ou CNPJ é <strong>inválido</strong>
          </mat-error>
        </mat-form-field>
        <div class="buttons">
          <button class="btn btn-primary">continuar</button>
          <a [routerLink]="['/login']" class="btn btn-secondary">já tenho cadastro</a>
        </div>
      </form>
    </div>
  </div>
  <div class="imagem">
    <img src="../assets/images/img_login.png" alt="Imagem abstrata com linhas brancas e pretas interligadas" />
  </div>
</section>

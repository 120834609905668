import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { ContractModalContainerComponent } from 'src/app/components/contract-modal-container/contract-modal-container.component';
import { Precatory, CreditorType } from 'src/app/domain/Precatory';
import { User } from 'src/app/domain/User';
import { PrecatoryService } from 'src/app/services/precatory.service';
import { UserService } from 'src/app/services/user.service';
import { CreditorTypeValidator } from 'src/app/validators/creditor-type.validator';

@Component({
  selector: 'app-new-precatory',
  templateUrl: './new-precatory.component.html',
  styleUrls: ['./new-precatory.component.scss']
})
export class NewPrecatoryComponent implements OnInit, AfterContentChecked {

  public me!: User;

  @ViewChild('stepper') stepper: MatStepper|null = null;

  public craft!: File;
  public contract!: File;
  public procuration!: File | undefined;

  public form = this.formBuilder.group({
    number: ['', [Validators.required]],
    budgetYear: [new Date().getFullYear(), [Validators.required]],
    processType: [null, [Validators.required]],
    precatoryType: [null, [Validators.required]],
    creditNature: [null, [Validators.required]],
    creditorType: [null, [Validators.required, CreditorTypeValidator(CreditorType.ATTORNEY, 'procuration')]],
    processNumber: ['', [Validators.required]],
    craft: [null, Validators.required],
    contract: [null, Validators.required],
    procuration: [null],
    amount: [0, [Validators.required]],
    discount: [0, [Validators.required]],
    partners: this.formBuilder.array([])
  });

  public hasPartner = false;
  public precatoryId!: number;

  constructor(private formBuilder: FormBuilder,
              private precatoryService: PrecatoryService,
              private userService: UserService,
              private snackBar: MatSnackBar,
              private ref: ChangeDetectorRef,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {

    this.userService.me$.subscribe(me => this.me = me as User);

    this.form.get('creditorType')?.valueChanges.subscribe(value => {
      if (value === CreditorType.ATTORNEY) {
        this.form.get('procuration')?.setValidators(Validators.required);
      } else {
        this.form.get('procuration')?.clearValidators();
      }
    });

    this.precatoryId = this.route.snapshot.params.id;
    if (this.precatoryId) {
      console.log('preca1', this.precatoryId)
      this.precatoryService.getById(this.precatoryId).subscribe(precatory => {
        precatory.partners.forEach(_ => this.addPartner());
        this.hasPartner = !!precatory.partners?.length;
        this.form.get('craft')?.clearValidators();
        this.form.get('contract')?.clearValidators();
        this.form.setValue(precatory.toForm());
      });
      console.log('pr2eca 2', this,this.precatoryService)
    }
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  getControl(formControlName: string): AbstractControl {
    return this.form.get(formControlName) as AbstractControl;
  }

  isFirst(): boolean {
    return !!this.stepper && this.stepper?.selectedIndex === 0;
  }


  isLast(): boolean {
    return !!this.stepper && this.stepper?.selectedIndex === this.stepper?.steps.length - 1;
  }

  next(): void {
    if (this.stepper && this.stepper?.selectedIndex < this.stepper?.steps.length - 1) {
      this.stepper?.next();
    } else {
      this.dialog.open(ContractModalContainerComponent, {
        autoFocus: false,
        data: {
          seller: true,
          callback: this.save.bind(this)
        }
      });
    }
  }

  private save(): void {
    const success = () => {
      this.dialog.open(ConfirmationModalComponent, {
          data: {
            title: 'Seu precatório foi cadastrado com sucesso.',
            text: 'Agora é só aguardar a análise que será feita até dois dias úteis.',
            close: () => {
              this.router.navigate(['/sell']);
            }
          }
        });
    };

    const error = (response: HttpErrorResponse) => {
      this.snackBar.open(response.error.message || 'Erro ao cadastrar precatório!', 'OK');
    };

    if (this.precatoryId) {
      this.precatoryService.update(this.precatoryId, this.form.value as Precatory, this.craft, this.contract, this.procuration)
        .subscribe(success, error);
    } else {
      this.precatoryService.create(this.form.value as Precatory, this.craft, this.contract, this.procuration)
        .subscribe(success, error);
    }
  }

  back(): void {
    this.stepper?.previous();
  }

  addPartner(): void {
    const partners = this.form.get('partners') as FormArray;
    partners.insert(
      partners.length,
      this.formBuilder.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        cpf: ['', [Validators.required]],
      })
    );
  }

  changePartner(event: any): void {
    const partners = this.form.get('partners') as FormArray;

    partners.clear();
    if (event.value) {
      this.addPartner();
    }
  }

  getPartnersControls(): FormGroup[] {
    const partners = this.form.get('partners') as FormArray;
    return partners.controls as FormGroup[];
  }

  calcDiscount(): number {
    const amount = this.getControl('amount').value;
    const discount = this.getControl('discount').value;

    return amount * (1.0 - discount / 100.0);
  }

  onCraftChange(event: any): void {
    this.craft = event.target.files[0];
    this.form.get('craft')?.setValue(event.target.files[0].name);
  }

  onContractChange(event: any): void {
    this.contract = event.target.files[0];
    this.form.get('contract')?.setValue(event.target.files[0].name);
  }

  onProcurationChange(event: any): void {
    this.procuration = event.target.files[0];
    this.form.get('procuration')?.setValue(event.target.files[0].name);
    this.form.get('creditorType')?.updateValueAndValidity();
  }

  onCreditorTypeChange(): void {
    this.procuration = undefined;
    this.form.get('procuration')?.reset();
  }

  yearList(): number[] {
    const list = [];

    let i = 1989;
    while (i <= new Date().getFullYear() + 2) {
      list.push(i);
      i++;
    }

    return list;
  }

  formatDiscountLabel(value: number): string {
    return `${value}%`;
  }

}

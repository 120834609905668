<section class="offer">
  <div class="title">
    <h2>Detalhe do precatório</h2>
    <p>Confira abaixo todas as informações do precatório ;)</p>
  </div>

  <section class="list">
    <app-precatory-details [precatory]="precatory"></app-precatory-details>

    <h3>Verifique aqui suas ofertas</h3>
    <ul class="offers">
      <li *ngFor="let offer of precatory?.offers">
        <div>
          <p class="title">Dia da oferta</p>
          <p>{{ offer.date | date:'dd/MM/YYYY' }}</p>
        </div>
        <div>
          <p class="title">Valor da oferta</p>
          <p>{{ offer.amount | brCurrency  }}</p>
        </div>
        <div>
          <p class="title">Valor com comissão</p>
          <p>{{ offer.sellerAmount | brCurrency  }} ({{ offer.sellerComission | number }}%)</p>
        </div>
        <div class="actions">
          <ng-container *ngIf="offer.isWaiting()">
            <button class="btn btn-ghost" (click)="accept(offer)">aprovar</button>
            <button class="btn btn-ghost-danger" (click)="reject(offer)">recusar</button>
          </ng-container>
          <a *ngIf="offer.isAccepted() && !precatory?.isCanceled()" [routerLink]="['/transactions/' + precatory?.transaction?.id]" class="btn btn-ghost">ir para a transação</a>
          <a *ngIf="offer.isAccepted() && precatory?.isCanceled()" [routerLink]="['/transactions/' + precatory?.transaction?.id]" class="btn btn-ghost status-0">transação cancelada</a>
          <p class="status status-{{ offer.status }}" *ngIf="!offer.isWaiting()">{{ offer.statusLabel }}</p>
        </div>
      </li>
    </ul>
    <p class="empty" *ngIf="!precatory?.hasOffer()">Você ainda não recebeu nenhuma oferta</p>
  </section>

  <div class="back">
    <a href="javascript:history.back()" class="btn btn-primary">voltar</a>
  </div>

</section>

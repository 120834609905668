
export class Notification {
  id!: number;
  objId!: number;
  type!: NotificationType;
  movement!: string;
  entityName!: string;

  static fromVO(vo: any): Notification {
    const n = new Notification();

    n.id = vo.id;
    n.objId = vo.objId;
    n.type = vo.type as NotificationType;
    n.movement = Movement.get(vo.movement as Movement);
    n.entityName = NotificationType.get(n.type);

    return n;
  }
}

export enum NotificationType {
  PRECATORY, TRANSACTION
}

// tslint:disable-next-line:no-namespace
export namespace NotificationType {
  export function get(type: NotificationType): string {
    switch (type) {
      case NotificationType.PRECATORY: return 'precatório';
      case NotificationType.TRANSACTION: return 'transação';
    }
  }
}

export enum Movement {
  PURCHASE, SALE
}

// tslint:disable-next-line:no-namespace
export namespace Movement {
  export function get(type: Movement): string {
    switch (type) {
      case Movement.PURCHASE: return 'compra';
      case Movement.SALE: return 'venda';
    }
  }
}

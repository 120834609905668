import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss']
})
export class AcceptTermsComponent implements OnInit {

  public terms = false;

  constructor(public dialogRef: MatDialogRef<AcceptTermsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                callback?: () => {}
              }) { }

  ngOnInit(): void {
  }

  continue(): void {
    this.terms = true;
  }

  accept(): void {
    if (this.data.callback) {
      this.data.callback();
    }

    this.dialogRef.close();
  }

}

import { Offer } from './Offer';
import { Precatory } from './Precatory';
import { User } from './User';

export class Transaction {
  id!: number;
  status!: number;
  precatory!: Precatory;
  offer!: Offer;
  buyer!: User;
  seller!: User;
  buyerSignature!: boolean;
  sellerSignature!: boolean;
  statusLabel!: string;

  static fromVO(vo: any): Transaction {
    const t = new Transaction();

    t.id = vo.id;
    t.status = vo.status;
    t.precatory = Precatory.fromVO(vo.precatory);
    t.offer = Offer.fromVO(vo.offer);
    t.buyer = User.fromVO(vo.buyer);
    t.seller = User.fromVO(vo.seller);
    t.buyerSignature = vo.buyerSignature;
    t.sellerSignature = vo.sellerSignature;

    switch (t.status) {
      case 0: t.statusLabel = 'Cancelada'; break;
      case 1: t.statusLabel = 'Contrato em edição'; break;
      case 2: t.statusLabel = 'Aguardando assinatura'; break;
      case 3: t.statusLabel = 'Aguardando pagamento'; break;
      case 4: t.statusLabel = 'Pagamento em validação'; break;
      case 5: t.statusLabel = 'Finalizada'; break;
    }

    return t;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { Offer, OfferStatus } from 'src/app/domain/Offer';
import { Precatory } from 'src/app/domain/Precatory';
import { PrecatoryService } from 'src/app/services/precatory.service';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit {

  public precatory!: Precatory;

  constructor(private precatoryService: PrecatoryService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.precatoryService.getById(this.route.snapshot.params.id).subscribe(
      precatory => this.precatory = precatory
    );
  }

  accept(offer: Offer): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Aprovar oferta',
        text: 'Tem certeza que deseja aprovar a oferta? Confirmando essa ação, você recusará todas as outras ofertas.',
        buttonLabel: 'sim, tenho certeza',
        callback: () => {
          this.precatoryService.updateOffer(this.precatory, offer, OfferStatus.ACCEPTED).subscribe(
            _ => {
              this.dialog.open(ConfirmationModalComponent, {
                data: {
                  title: 'Oferta aprovada com sucesso',
                  text: 'Você receberá instruções de como proceder para concluir a venda',
                  close: () => {
                    this.load();
                  }
                }
              });
            },
            _ => {
              this.snackBar.open('Erro ao aprovar oferta', 'OK');
            }
          );
        }
      }
    });
  }

  reject(offer: Offer): void {
    this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Recusar oferta',
        text: 'Tem certeza que deseja recusar a oferta recebida?',
        buttonLabel: 'sim, tenho certeza',
        callback: () => {
          this.precatoryService.updateOffer(this.precatory, offer, OfferStatus.REJECTED).subscribe(
            _ => {
              this.dialog.open(ConfirmationModalComponent, {
                data: {
                  title: 'Oferta recusada com sucesso',
                  text: 'Você terá novas ofertas para avaliar e vender seu precatório',
                  close: () => {
                    this.load();
                  }
                }
              });
            },
            _ => {
              this.snackBar.open('Erro ao recusar oferta', 'OK');
            }
          );
        }
      }
    });
  }

}

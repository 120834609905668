import { Precatory } from './Precatory';
import { User } from './User';

export class Offer {
  id!: number;
  amount!: number;
  date!: Date;
  status!: OfferStatus;
  statusLabel!: string;
  precatory!: Precatory;
  buyer!: User;
  buyerComission!: number;
  sellerComission!: number;
  buyerAmount!: number;
  sellerAmount!: number;


  static fromVO(vo: any): Offer {
    const o = new Offer();

    o.id = vo.id,
    o.amount = parseFloat(vo.amount),
    o.date = vo.date,
    o.status = vo.status;
    o.statusLabel = OfferStatus.get(vo.status);
    o.buyerComission = vo.buyerComission;
    o.sellerComission = vo.sellerComission;
    o.buyerAmount = vo.buyerAmount;
    o.sellerAmount = vo.sellerAmount;

    if (vo.precatory) {
      o.precatory = Precatory.fromVO(vo.precatory);
    }

    if (vo.buyer) {
      o.buyer = User.fromVO(vo.buyer);
    }

    return o;
  }

  isWaiting(): boolean {
    return this.status === OfferStatus.WAITING;
  }

  isAccepted(): boolean {
    return this.status === OfferStatus.ACCEPTED;
  }

  isRejected(): boolean {
    return this.status === OfferStatus.REJECTED;
  }
}

export enum OfferStatus {
  REJECTED, WAITING = 1, ACCEPTED
}

// tslint:disable-next-line:no-namespace
export namespace OfferStatus {
  export function get(type: OfferStatus): string {
    switch (type) {
      case OfferStatus.WAITING: return 'Oferta enviada';
      case OfferStatus.ACCEPTED: return 'Oferta aprovada';
      case OfferStatus.REJECTED: return 'Oferta recusada';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/domain/User';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public me!: User;

  constructor(public router: Router,
              private authService: AuthService,
              private userService: UserService) { }

  ngOnInit(): void {
    this.userService.me$.subscribe(me => this.me = me as User);
  }

  logout(): void {
    this.authService.logout();
    window.location.pathname = '/login';
  }

}

<h3>CONTRATO DE DISPONIBILIZAÇÃO DE PLATAFORMA DIGITAL PARA A REALIZAÇÃO DE CESSÃO ONEROSA DE CRÉDITO DE PRECATÓRIOS.</h3>

<p>Considerando que a CONTRATADA é titular de plataforma digital leeq, voltada à intermediação de cessão onerosa de crédito de precatório, aqui chamada simplesmente de “compra e venda de precatório”, disponibilizada no site www.leeq.com.br;</p>
<p>Considerando que na plataforma pessoas interessadas em adquirir crédito de precatório cadastram-se na plataforma, buscando oportunidades de adquirir o crédito, sendo essas pessoas as únicas responsáveis pelas informações cadastradas;</p>
<p>Considerando que o CONTRATANTE é pessoa legitimamente interessada em vender crédito de precatório por si titularizado, firmando contrato de compra e venda de precatório com pessoas cadastradas ou que venham a se cadastrar na plataforma;</p>
<p>As partes acima qualificadas têm entre si, justo e firmado o presente Contrato de Intermediação de compra e venda de precatório, através da plataforma Leeq, o qual será regido pelas cláusulas abaixo e condições gerais anexas.</p>

<b>DO OBJETO DO CONTRATO</b>

<p>Cláusula 1ª. O presente contrato tem como objeto a disponibilização de plataforma digital, disponível no site www.leeq.com.br, para a realização da venda de precatório.</p>
<p>§1ª. A CONTRATADA disponibilizará ao CONTRATANTE plataforma digital que viabiliza o cadastro do seu crédito de precatório, assim como os meios para a efetivação do contrato de compra e venda do crédito.</p>
<p>§2º. Na plataforma, o CONTRATANTE poderá:</p>
<p>I – Pesquisar informações e condições para a compra e venda de crédito de precatório;</p>
<p>II – Negociar valor do crédito de precatório;</p>
<p>III – Receber o pagamento do crédito de precatório;</p>
<p>IV – Efetivar o contrato de compra e venda;</p>
<p>V – Citar funcionalidades.</p>
<p>§3º. Não é parte do objeto contratual a análise acerca da qualidade dos créditos de precatório inseridos na plataforma, tampouco a análise da solvência do potencial comprador interessado no crédito.</p>

<b>OBRIGAÇÕES DA CONTRATADA</b>

<p>Cláusula 2ª. A CONTRATADA deverá prestar contas ao CONTRATANTE dos valores pagos e disponibilizar o Contrato de Compra e Venda de precatório.</p>
<p>Cláusula 3ª. A contratada não assume qualquer obrigação judicial, tampouco cartorária, sendo dever do CONTRATANTE adotar as medidas cabíveis para auxiliar no efetivo recebimento do crédito de precatório pelo comprador.</p>

<b>DAS OBRIGAÇÕES DO CONTRATANTE</b>

<p>Cláusula 4ª. É dever do CONTRATANTE se responsabilizar pelos dados inseridos na plataforma, podendo responder civil, administrativa e criminalmente pelos dados inseridos na plataforma.</p>
<p>Cláusula 5ª. Fica o CONTRATANTE obrigado a realizar transação de compra através da plataforma LEEQ, quando a oferta feita pelo potencial comprador.</p>
<p><b>§1º. Caso o CONTRATANTE transacione o precatório cadastrado na plataforma, por fora desta, ainda que com intermédio pessoa terceira, ficará obrigada a arcar com multa em favor da CONTRATANTE no valor correspondente 10% do valor do precatório.</b></p>
<p><b>§2º. O CONTRATANTE entrega o crédito de precatório, cadastrado na LEEQ como GARANTIA das obrigações previstas neste contrato, sobretudo da contida no §1º do art. 5º.</b></p>
<p><b>§3º O CONTRATANTE autoriza a CONTRATADA a habilitar-se nos autos do precatório para receber o importe de 10% do valor do precatório indicado no §2º desta cláusula, a fim de que o valor devido seja recebido diretamente do ente pagador, independentemente de qualquer ação judicial.</b></p>

<br>
<br>

<p class="center">
  <button (click)="accept()" class="btn btn-primary" [class.disabled]="accepted">{{ accepted ? 'estou de acordo :)' : 'clique para confirmar que está de acordo' }}</button>
</p>

<br>
<br>

<b>DA LIBERDADE NEGOCIAL DO POTENCIAL COMPRADOR DE PRECATÓRIO</b>

<p>Cláusula 5ª. Uma vez iniciada a negociação do crédito de precatório, o CONTRATANTE, assim como o POTENCIAL COMPRADOR poderão desistir do negócio, caso ainda não tenham assinado o contrato de compra e venda do crédito.</p>
<p>Parágrafo único. Havendo a desistência do negócio, seja por parte do CONTRATANTE e/ou do POTENCIAL COMPRADOR, a CONTRATADA estará desobrigada de arcar com qualquer custo além daqueles por si assumidos diretamente, relacionado às despesas de funcionamento da plataforma.</p>
<p>Cláusula 6ª. A CONTRATADA é mera intermediária entre as partes, isto é, CONTRATANTE e POTENCIAL COMPRADOR, não assumindo para si as obrigações, contratuais e/ou legais, próprias das partes.</p>

<b>DO VALOR DA INTERMEDIAÇÃO</b>

<p>Cláusula 7ª. Pela intermediação, será pago à CONTRATADA o importe de 5% (cinco porcento) do valor da compra e venda do crédito de precatório.</p>
<p>Parágrafo único. O valor fixado no caput da cláusula 7ª deverá ser pago no momento da transferência do valor de compra.</p>

<b>DA DEFESA DA CONTRATADA</b>

<p>Cláusula 8ª. Na extensão máxima permitida pela legislação aplicável, o CONTRATANTE concorda em defender a CONTRATADA face a quaisquer reivindicações, responsabilidades, danos, perdas e despesas, incluindo, sem limitação, honorários contábeis e advocatícios resultantes, decorrentes de ou de alguma forma relacionadas a: (i) violação destes Termos (incluindo quaisquer termos suplementares ou adicionais que se relacionem ao uso da plataforma) ou nossas Políticas ou Normas, (ii) o uso indevido da Plataforma, (iii) sua interação com qualquer usuário da plataforma, incluindo, sem limitação, quaisquer lesões, perdas ou danos (sejam compensatórios, diretos, relacionados consequenciais ou outros) de qualquer tipo decorrentes ou resultantes de tal interação, contratação, participação ou negociação, (iv) erro em relação a você, ao declarar, recolher ou repassar Impostos com precisão, ou (v) violação de quaisquer leis, regulamentos ou direitos de terceiros, tais como direitos de propriedade intelectual ou de privacidade.</p>

<b>CONSIDERAÇÕES FINAIS</b>

<p>Cláusula 9ª. Interpretação Destes Termos. Exceto na medida em que puderem ser complementados por termos, condições, políticas, diretrizes, normas e divulgações adicionais, este contrato constitui acordo integral entre a CONTRATADA e você com relação ao seu acesso ou uso da Plataforma LEEQ e prevalece sobre todo e qualquer acordo oral ou escrito anterior. Este contrato não se destina a conferir nenhum direito ou recurso a qualquer pessoa que não seja você e a CONTRATADA.</p>
<p>Cláusula 10ª. Caso alguma disposição destes Termos seja considerada inválida ou inexequível, salvo indicação em contrário, a referida disposição será removida e não afetará a validade e a exequibilidade das disposições remanescentes.</p>
<p>Cláusula 11ª. O não exercício de qualquer dos direitos previstos neste contrato não implicará em renúncia, podendo ser executado a qualquer momento, salvo reconhecimento por escrito da CONTRATADA.</p>
<p>Cláusula 12ª. O CONTRATANTE não pode ceder, transferir ou delegar este contrato e seus direitos e obrigações a terceiros sem o consentimento prévio e por escrito da CONTRATADA.</p>
<p>Cláusula 13ª. <b>Caso Fortuito. A CONTRATADA não será responsável por qualquer atraso ou falha no desempenho resultante de causas fora de seu devido controle, incluindo, entre outros, atos da natureza, desastres naturais, guerra, terrorismo, tumultos, embargos, atos de autoridades civis ou militares, incêndios, inundações, acidentes, pandemias, epidemias, doenças, greves ou interrupções de serviços de transporte, combustível, energia, mão de obra ou materiais.</b></p>
<p>Cláusula 14ª. <b>Eventuais despesas tributárias incidentes sobre as operações realizadas através da plataforma serão de responsabilidade do CONTRATANTE.</b></p>
<p>Cláusula 15ª. <b>O presente acordo constitui título executivo extrajudicial nos termos do art. 784, inc. III do CPC/2015.</b></p>
<p>Sendo esta a expressão da vontade do CONTRATANTE e CONTRATADA, firmam o presente contrato em duas vias de igual teor e forma, <b>elegendo o Fórum da Cidade de São Paulo, estado de São Paulo, com exclusão de qualquer outro</b> por mais privilegiado que seja para dirimir as questões que eventualmente se originarem deste contrato.</p>

<br>
<br>

<p class="center">São Paulo, {{ getDate() }}.</p>

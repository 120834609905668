<section class="dashboard">
  <div class="title">
    <h2>Bem vindo a Leeq</h2>
    <p>Você possui um precatório? Já pensou em transformar em dinheiro antes do esperado? Que tal dar uma olhadinha no mercado? Vem com a gente. Essa é a Leeq, simplificando de maneira transparente e segura a forma como você compra e vende precatórios.</p>
  </div>
  <ul class="actions" *ngIf="!me?.isAdmin()">
    <li>
      <a [routerLink]="['/sell']">
        <h3>Quero vender</h3>
        <img src="../assets/images/icon-sell.svg" alt="Ícone de um cartão de crédito." />
        <p>Cadastre seu precatório que nós fazemos todo o trabalho, você só precisa decidir a melhor oferta.</p>
        <span class="btn btn-ghost">cadastrar precatório</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/buy']">
        <h3>Quero comprar</h3>
        <img src="../assets/images/icon-buy.svg" alt="Ícone de um cartão de crédito." />
        <p>Tá esperando o quê? Encontre os melhores precatórios do mercado aqui na Leeq.<br><br></p>
        <span class="btn btn-ghost">encontrar precatório</span>
      </a>
    </li>
  </ul>
</section>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { ContractModalContainerComponent } from 'src/app/components/contract-modal-container/contract-modal-container.component';
import { Precatory } from 'src/app/domain/Precatory';
import { BrCurrencyPipe } from 'src/app/pipes/br-currency.pipe';
import { PrecatoryService } from 'src/app/services/precatory.service';

const DEFAULT_BUYER_COMISSION_VALUE = 1;
const MIN_VALUE_OFFER_PERCENT = 0.6;

@Component({
  selector: 'app-make-offer',
  templateUrl: './make-offer.component.html',
  styleUrls: ['./make-offer.component.scss']
})
export class MakeOfferComponent implements OnInit {

  public form: FormGroup = this.formBuilder.group({
    amount: [null, [Validators.required]]
  });

  public precatory!: Precatory;

  constructor(private precatoryService: PrecatoryService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.precatoryService.getById(this.route.snapshot.params.id).subscribe(
      precatory => {
        this.precatory = precatory;
        this.form.get('amount')?.setValidators([
          Validators.required,
          Validators.min(precatory.amountWithDiscount * MIN_VALUE_OFFER_PERCENT)
        ]);
      }
    );
  }

  offer(): void {
    if (this.form.invalid) { return; }
    console.log(this.form.invalid);
    console.log(this.form.valid);

    this.dialog.open(ContractModalContainerComponent, {
      autoFocus: false,
      data: {
        seller: false,
        callback: () => {
          this.precatoryService.makeOffer(this.precatory, this.form.value.amount).subscribe(
            _ => {
              this.dialog.open(ConfirmationModalComponent, {
                data: {
                  title: 'Oferta enviada',
                  text: 'Sua oferta foi enviada com sucesso, agora é só aguardar a avaliação do vendedor.',
                  close: () => {
                    this.router.navigate(['/buy']);
                  }
                }
              });
            },
            error => {
              this.snackBar.open('Erro ao enviar oferta.', 'OK');
            }
          );
        }
      }
    });
  }

  comissionAmountLabel(amount: number, comission?: number): string {
    if (!amount) { return ''; }
    comission = comission || DEFAULT_BUYER_COMISSION_VALUE;
    const value = amount * (1 + comission / 100.0);
    return `${new BrCurrencyPipe().transform(value)} (${comission}% de comissão)`;
  }

}


import { Component, OnInit } from '@angular/core';
import { Precatory } from 'src/app/domain/Precatory';
import { PrecatoryService } from 'src/app/services/precatory.service';

@Component({
  selector: 'app-admin-precatory-list',
  templateUrl: './admin-precatory-list.component.html',
  styleUrls: ['./admin-precatory-list.component.scss']
})
export class AdminPrecatoryListComponent implements OnInit {

  public precatories: Precatory[] = [];

  public type!: number;
  public year!: number;
  public amount!: number;
  public status!: number;

  constructor(private precatoryService: PrecatoryService) { }

  ngOnInit(): void {
    this.find();
  }

  find(): void {
    this.precatoryService.findPrecatories(this.type, this.year, this.amount, this.status).subscribe(
      precatories => this.precatories = precatories
    );
  }

  yearList(): number[] {
    const list = [];

    let i = 1989;
    while (i <= new Date().getFullYear() + 2) {
      list.push(i);
      i++;
    }

    return list;
  }
}

import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { UserService } from '../services/user.service';

export class UniqueEmail {
  static createValidator(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors|null> => {
      return userService.validEmail(control.value).toPromise()
        .then(_ => null)
        .catch(_ => ({ nonUniqueEmail: true }));
    };
  }
}
